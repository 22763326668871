/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Flex, Heading, Stack } from '@chakra-ui/react';
import _ from 'lodash';

import { useAppSelector } from '@/app/hooks';
import { BarGraph } from '@/common/components/barGraph';
import { EmptyDataAlert } from '@/common/components/errorAlert';
import { LoadingSpinner } from '@/common/components/loadingSpinner';
import { Status } from '@/common/interfaces';
import { display2Decimals } from '@/common/utils';
import { compactLargeNumber } from '@/common/utils/utils';
import { selectBehaviorWalkthrough } from '@/features/dashboard/slice';
import colors from '@/theme/foundations/colors';

import {
  onOffChartProps,
  onOffWalkthroughDataFormatter,
  prePostChartProps,
  prePostWalkthroughDataFormatter,
} from './helper';
import {
  IncrementalTextHandler,
  OnOffTextHandler,
  PrePostTextHandler,
  StepHandler,
  TotalLabels,
} from './HelperComponents';
import { IncrementalImpactGraph } from './IncrementalImpactGraph';
import { selectBehavior, selectBehaviorStatus } from './slice';
import { ControlChartSegments, controlChartSegments } from './types';

const PrePostControlGraph = ({
  showTitle = false,
  showLeftText = true,
  showDropdownItems = true,
  hasEnoughData = true,
  walkthroughExitHandler,
  walkthroughIncreaseClickHandler,
  walkthroughDecreaseClickHandler,
  walkthroughStartHandler,
}: {
  showTitle?: boolean;
  showLeftText?: boolean;
  showDropdownItems?: boolean;
  hasEnoughData?: boolean;
  walkthroughExitHandler?: any;
  walkthroughIncreaseClickHandler?: any;
  walkthroughDecreaseClickHandler?: any;
  walkthroughStartHandler?: any;
}) => {
  const { t } = useTranslation();

  const graphColors: Record<string, string> = {
    'Upside Customer': colors.green[400],
    'Similar Customer': colors.blue[800],
  };
  const { prePostChart } = useAppSelector(selectBehavior);
  const loadingStatus = useAppSelector(selectBehaviorStatus);
  const behaviorWalkthroughState = useAppSelector(selectBehaviorWalkthrough);

  const [selectedSegment, setSelectedSegment] = useState<ControlChartSegments>('all_segments');
  const [selectedValueType, setSelectedValueType] = useState<'sales' | 'transactions'>('sales');

  const isEmptyData = loadingStatus === Status.Idle && _.isEmpty(prePostChart);
  const selectedData = useMemo(() => {
    if (!prePostChart) {
      return [];
    }

    if (!hasEnoughData) {
      return [
        {
          id: 'fakeData',
          data: [
            {
              [t('behaviorPage.prePostVsControl.dataLabels.similarCustomer')]: 0.4,
              [t('behaviorPage.prePostVsControl.dataLabels.upsideCustomer')]: 0.6,
              label: t('behaviorPage.prePostVsControl.dataLabels.beforeUpside'),
            },
            {
              [t('behaviorPage.prePostVsControl.dataLabels.similarCustomer')]: 0.4,
              [t('behaviorPage.prePostVsControl.dataLabels.upsideCustomer')]: 0.9,
              label: t('behaviorPage.prePostVsControl.dataLabels.afterUpside'),
            },
          ],
        },
      ];
    }
    const data =
      behaviorWalkthroughState && behaviorWalkthroughState.step !== 0
        ? [prePostChart['sales']['all_segments']]
        : [prePostChart[selectedValueType][selectedSegment]];
    return data;
  }, [prePostChart, selectedSegment, selectedValueType, hasEnoughData, t, behaviorWalkthroughState]);

  const selectChartType = useCallback(
    (selected: string) => setSelectedValueType(selected as 'sales' | 'transactions'),
    [],
  );

  const selectChartSegment = useCallback(
    (selected: string) => setSelectedSegment(selected as ControlChartSegments),
    [],
  );

  const dropdownItems = useMemo(() => {
    return [
      {
        items: [
          { value: 'sales', label: t(`components.chartDropDownMenu.prePostControlChartDropdown.sales`) },
          {
            value: 'transactions',
            label: t(`components.chartDropDownMenu.prePostControlChartDropdown.transactions`),
          },
        ],
        selectedItem: selectedValueType,
        itemSelector: selectChartType,
      },
      {
        items: [
          ...controlChartSegments.map((segment) => ({
            value: segment,
            label: t(`components.chartDropDownMenu.prePostControlChartDropdown.${segment}`),
          })),
        ],
        selectedItem: selectedSegment,
        itemSelector: selectChartSegment,
      },
    ];
  }, [selectedSegment, t, selectedValueType, selectChartSegment, selectChartType]);

  const showDropdown = useMemo(() => {
    return showDropdownItems && hasEnoughData && behaviorWalkthroughState && !behaviorWalkthroughState.initiated
      ? dropdownItems
      : [];
  }, [behaviorWalkthroughState, showDropdownItems, hasEnoughData, dropdownItems]);

  const increasedValue = useMemo(() => {
    if (!prePostChart || !hasEnoughData) {
      return 0;
    }

    if (selectedValueType === 'sales') {
      return display2Decimals(prePostChart.metrics.sales_increase_ratio, 1);
    }

    return display2Decimals(prePostChart.metrics.transaction_increase_ratio, 1);
  }, [selectedValueType, prePostChart, hasEnoughData]);

  const formatter = (value: string | number) => {
    if (selectedValueType === 'sales' || (behaviorWalkthroughState && behaviorWalkthroughState.step !== 0)) {
      return compactLargeNumber(value as number);
    }

    return display2Decimals(value, 2);
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const FormattedLabels = (props: any) => {
    return TotalLabels({ ...props, ...{ formatter } });
  };

  const walkthroughData =
    behaviorWalkthroughState && prePostWalkthroughDataFormatter(selectedData, behaviorWalkthroughState);

  return (
    <Flex w={'100%'} gap={{ base: 6, xl: 4 }} flexDirection={{ base: 'column', xl: 'row' }}>
      {showLeftText && (
        <Flex
          direction={'column'}
          justifyContent={'space-between'}
          flexGrow={1}
          flexShrink={0}
          flexBasis={{ base: 'auto', xl: '30%' }}
          paddingLeft={'16px'}
        >
          {loadingStatus === Status.Loading ? (
            <Stack h={'100%'} w={'100%'} align={'center'} justify={'center'}>
              <LoadingSpinner />
            </Stack>
          ) : (
            <>
              <PrePostTextHandler
                walkthroughStartHandler={walkthroughStartHandler}
                selectedData={selectedData}
                dropdownItems={dropdownItems}
                increasedValue={increasedValue}
              />
              <StepHandler
                walkthroughIncreaseClickHandler={walkthroughIncreaseClickHandler}
                walkthroughExitHandler={walkthroughExitHandler}
                walkthroughDecreaseClickHandler={walkthroughDecreaseClickHandler}
                startStep={1}
                endStep={4}
                stepAmount={4}
              />
            </>
          )}
        </Flex>
      )}
      {isEmptyData ? (
        <EmptyDataAlert />
      ) : (
        <Box flexGrow={1} flexShrink={0} flexBasis={{ base: 'auto', xl: showLeftText ? '70%' : '100%' }}>
          {loadingStatus === Status.Loading ? (
            <Stack h={'100%'} w={'100%'} align={'center'} justify={'center'}>
              <LoadingSpinner />
            </Stack>
          ) : (
            behaviorWalkthroughState &&
            behaviorWalkthroughState.step !== 1 && (
              <Flex gap={2} ml={'48px'} mb={'1rem'}>
                {behaviorWalkthroughState && behaviorWalkthroughState.step !== 0 ? (
                  <Heading as='h3' size='sm'>
                    {t(`behaviorPage.prePostVsControl.cardTitle.${dropdownItems[0].selectedItem}`, {
                      value: 'sales',
                    })}
                  </Heading>
                ) : (
                  <>
                    <Heading as='h4' size='sm'>
                      {t(`behaviorPage.prePostVsControl.cardTitle.${dropdownItems[0].selectedItem}`, {
                        value: dropdownItems[0].selectedItem,
                      })}
                    </Heading>
                  </>
                )}
              </Flex>
            )
          )}
          <BarGraph
            data={behaviorWalkthroughState && behaviorWalkthroughState.step !== 0 ? walkthroughData : selectedData}
            loadingStatus={loadingStatus}
            title={showTitle ? 'behaviorPage.prePostVsControl.graph.title' : ''}
            width='99%'
            wideLegend={true}
            customProps={
              behaviorWalkthroughState &&
              prePostChartProps(behaviorWalkthroughState, FormattedLabels, graphColors, selectedValueType)
            }
            dropdownMenuProps={showDropdown}
          />
        </Box>
      )}
    </Flex>
  );
};

export const OnOffGraph = ({
  hasEnoughData = true,
  walkthroughExitHandler,
  walkthroughIncreaseClickHandler,
  walkthroughDecreaseClickHandler,
  walkthroughStartHandler,
}: {
  hasEnoughData?: boolean;
  walkthroughExitHandler: any;
  walkthroughIncreaseClickHandler: any;
  walkthroughDecreaseClickHandler: any;
  walkthroughStartHandler: any;
}) => {
  const { t } = useTranslation();
  const [selectedFilter, setSelectedFilter] = useState<'all_customers' | 'new' | 'existing'>('all_customers');
  const loadingStatus = useAppSelector(selectBehaviorStatus);

  const { onOffChart } = useAppSelector(selectBehavior);
  const behaviorWalkthroughState = useAppSelector(selectBehaviorWalkthrough);

  const isEmptyData = loadingStatus === Status.Idle && _.isEmpty(onOffChart);

  const formatter: (value: string | number) => string = (value: string | number) => {
    return compactLargeNumber(value as number);
  };

  const FormattedLabels = (props: any) => {
    return TotalLabels({ ...props, ...{ formatter } });
  };
  const selectedData = useMemo(() => {
    if (!onOffChart) {
      return [];
    }

    if (!hasEnoughData) {
      return [
        {
          id: 'fakeData',
          data: [
            {
              [t('behaviorPage.onOffGraph.dataLabels.similarCustomer')]: 0.4,
              [t('behaviorPage.onOffGraph.dataLabels.upsideCustomer')]: 0,
              label: t('behaviorPage.onOffGraph.dataLabels.beforeUpside'),
            },
            {
              [t('behaviorPage.onOffGraph.dataLabels.similarCustomer')]: 0.4,
              [t('behaviorPage.onOffGraph.dataLabels.upsideCustomer')]: 0.9,
              label: t('behaviorPage.onOffGraph.dataLabels.afterUpside'),
            },
          ],
        },
      ];
    }

    const data =
      behaviorWalkthroughState && behaviorWalkthroughState.step !== 0
        ? [onOffChart['sales']['all_customers']]
        : [onOffChart['sales'][selectedFilter]];
    return data;
  }, [selectedFilter, onOffChart, hasEnoughData, t, behaviorWalkthroughState]);

  const dropdownItems = useMemo(() => {
    return [
      {
        items: [
          {
            value: 'all_customers',
            label: t(`components.chartDropDownMenu.onOffChartChartDropdown.all_customers`),
          },
          { value: 'new', label: t(`components.chartDropDownMenu.onOffChartChartDropdown.newCustomers`) },
          {
            value: 'existing',
            label: t(`components.chartDropDownMenu.onOffChartChartDropdown.existing`),
          },
        ],
        selectedItem: selectedFilter,
        itemSelector: (value: string) => setSelectedFilter(value as 'all_customers' | 'new' | 'existing'),
      },
    ];
  }, [selectedFilter, t, setSelectedFilter]);

  const showDropdown = useMemo(() => {
    return hasEnoughData && behaviorWalkthroughState && !behaviorWalkthroughState.initiated ? dropdownItems : [];
  }, [behaviorWalkthroughState, hasEnoughData, dropdownItems]);

  const newAndExistingSalesTotal = useMemo(() => {
    if (!hasEnoughData) return 0;

    return onOffChart && compactLargeNumber(onOffChart.metrics.new_and_existing_sales_total);
  }, [hasEnoughData, onOffChart]);

  const upsideLaunchDate = useMemo(() => {
    if (!hasEnoughData) return new Intl.DateTimeFormat('en-US', { dateStyle: 'long' }).format(new Date());

    return (
      onOffChart &&
      new Intl.DateTimeFormat('en-US', { dateStyle: 'long' }).format(Date.parse(onOffChart.metrics.upside_launch_date))
    );
  }, [hasEnoughData, onOffChart]);

  const nonPromoSales = useMemo(() => {
    if (!hasEnoughData) return 0;

    return onOffChart && compactLargeNumber(onOffChart.metrics.non_promo_sales_total);
  }, [hasEnoughData, onOffChart]);

  const walkthroughData =
    behaviorWalkthroughState && onOffWalkthroughDataFormatter(selectedData, behaviorWalkthroughState);

  return (
    <Flex w={'100%'} flexDirection='column' data-testid='onOffGraphCard'>
      <Flex w={'100%'} gap={{ base: 6, xl: 4 }} flexDirection={{ base: 'column', xl: 'row' }} pb={'20px'}>
        <Flex
          direction={'column'}
          justifyContent={'space-between'}
          flexGrow={1}
          flexShrink={0}
          flexBasis={{ base: 'auto', xl: '30%' }}
          fontSize={'18px'}
          paddingLeft={'16px'}
          data-testid='onOfftext'
        >
          {loadingStatus === Status.Loading ? (
            <Stack h={'100%'} w={'100%'} align={'center'} justify={'center'}>
              <LoadingSpinner />
            </Stack>
          ) : (
            <>
              <OnOffTextHandler
                newAndExistingSalesTotal={newAndExistingSalesTotal}
                upsideLaunchDate={upsideLaunchDate}
                nonPromoSales={nonPromoSales}
                walkthroughStartHandler={walkthroughStartHandler}
                selectedData={selectedData}
              />
              <StepHandler
                walkthroughIncreaseClickHandler={walkthroughIncreaseClickHandler}
                walkthroughExitHandler={walkthroughExitHandler}
                walkthroughDecreaseClickHandler={walkthroughDecreaseClickHandler}
                startStep={5}
                endStep={8}
                stepAmount={4}
              />
            </>
          )}
        </Flex>
        <Box flexGrow={1} flexShrink={0} flexBasis={{ base: 'auto', xl: '70%' }} data-testid='onOffGraph'>
          {loadingStatus === Status.Loading ? (
            <Stack h={'100%'} w={'100%'} align={'center'} justify={'center'}>
              <LoadingSpinner />
            </Stack>
          ) : (
            <Heading as='h3' size='sm' ml={'48px'}>
              {t('behaviorPage.onOffGraph.blurb1')}
            </Heading>
          )}
          {isEmptyData ? (
            <EmptyDataAlert />
          ) : (
            <BarGraph
              data={behaviorWalkthroughState && behaviorWalkthroughState.step !== 0 ? walkthroughData : selectedData}
              loadingStatus={loadingStatus}
              title=''
              width='99%'
              customProps={behaviorWalkthroughState && onOffChartProps(behaviorWalkthroughState, FormattedLabels)}
              wideLegend={true}
              dropdownMenuProps={showDropdown}
            />
          )}
        </Box>
      </Flex>
    </Flex>
  );
};

const IncrementalImpact = ({
  walkthroughExitHandler,
  walkthroughIncreaseClickHandler,
  walkthroughDecreaseClickHandler,
  walkthroughStartHandler,
}: {
  walkthroughExitHandler: any;
  walkthroughIncreaseClickHandler: any;
  walkthroughDecreaseClickHandler: any;
  walkthroughStartHandler: any;
}) => {
  const { t } = useTranslation();
  const loadingStatus = useAppSelector(selectBehaviorStatus);
  const { incrementalImpactChart } = useAppSelector(selectBehavior);
  const behaviorWalkthroughState = useAppSelector(selectBehaviorWalkthrough);

  const [selectedValueType, setSelectedValueType] = useState<'sales' | 'transactions'>('sales');
  const [selectedDateFormat, setDateFormat] = useState<'relativeMonths' | 'calendarMonths'>('relativeMonths');

  const selectChartType = useCallback(
    (selected: string) => setSelectedValueType(selected as 'sales' | 'transactions'),
    [],
  );

  const selectDateFormat = useCallback(
    (selected: string) => setDateFormat(selected as 'relativeMonths' | 'calendarMonths'),
    [],
  );

  const selectedData = useMemo(() => {
    if (!incrementalImpactChart) {
      return [];
    }

    const data =
      behaviorWalkthroughState && behaviorWalkthroughState.step !== 0
        ? incrementalImpactChart['walkthrough']['relativeMonths']
        : incrementalImpactChart[selectedValueType][selectedDateFormat];
    return data;
  }, [selectedValueType, selectedDateFormat, incrementalImpactChart, behaviorWalkthroughState]);

  const dropdownItems = useMemo(() => {
    return [
      {
        items: [
          {
            value: 'transactions',
            label: t(`components.chartDropDownMenu.prePostControlChartDropdown.transactions`),
          },
          { value: 'sales', label: t(`components.chartDropDownMenu.prePostControlChartDropdown.sales`) },
        ],
        selectedItem: selectedValueType,
        itemSelector: selectChartType,
      },
      {
        items: [
          { value: 'relativeMonths', label: t(`components.chartDropDownMenu.incrementalImpactChartDropdown.relative`) },
          {
            value: 'calendarMonths',
            label: t(`components.chartDropDownMenu.incrementalImpactChartDropdown.calendar`),
          },
        ],
        selectedItem: selectedDateFormat,
        itemSelector: selectDateFormat,
      },
    ];
  }, [t, selectedValueType, selectChartType, selectedDateFormat, selectDateFormat]);

  const showDropdown = useMemo(() => {
    return behaviorWalkthroughState && !behaviorWalkthroughState.initiated ? dropdownItems : [];
  }, [behaviorWalkthroughState, dropdownItems]);

  return (
    <Flex w={'100%'} flexDirection='column'>
      <Flex gap={{ base: 6, xl: 4 }} flexDirection={{ base: 'column', xl: 'row' }}>
        <Flex
          flexGrow={1}
          flexShrink={0}
          flexBasis={{ base: 'auto', xl: '30%' }}
          direction={'column'}
          justifyContent={'space-between'}
        >
          {loadingStatus === Status.Loading ? (
            <Stack h={'100%'} w={'100%'} align={'center'} justify={'center'}>
              <LoadingSpinner />
            </Stack>
          ) : (
            <>
              <IncrementalTextHandler walkthroughStartHandler={walkthroughStartHandler} />
              <StepHandler
                walkthroughIncreaseClickHandler={walkthroughIncreaseClickHandler}
                walkthroughExitHandler={walkthroughExitHandler}
                walkthroughDecreaseClickHandler={walkthroughDecreaseClickHandler}
                startStep={9}
                endStep={13}
                stepAmount={5}
              />
            </>
          )}
        </Flex>
        <Box flexGrow={1} flexShrink={0} flexBasis={{ base: 'auto', xl: '70%' }}>
          {loadingStatus === Status.Loading ? (
            <Stack h={'100%'} w={'100%'} align={'center'} justify={'center'}>
              <LoadingSpinner />
            </Stack>
          ) : (
            <Flex gap={2}>
              <Heading as='h3' size='sm'>
                {t('behaviorPage.incrementalImpactGraph.cardTitle', {
                  value: `${selectedValueType.charAt(0).toUpperCase()}${selectedValueType.slice(1)}`,
                })}
              </Heading>
              <Heading as='h4' size='sm'>
                {t('behaviorPage.incrementalImpactGraph.cardSubtitle', { value: selectedValueType })}
              </Heading>
            </Flex>
          )}

          <IncrementalImpactGraph
            loadingStatus={loadingStatus}
            dropdownMenuProps={showDropdown}
            dataType={selectedValueType}
            dateFormat={selectedDateFormat}
            data={selectedData}
          />
        </Box>
      </Flex>
    </Flex>
  );
};

export const CardsViewContent = {
  PrePostControlGraph,
  OnOffGraph,
  IncrementalImpact,
};
