import * as Sentry from '@sentry/react';
import axios from 'axios';

import { axiosInstance } from '@/common/utils';
import { checkBillingPermissions } from '@/features/auth/permissions';

import { IPlaidToken } from '../hooks';

import {
  IAddBillingMethodRequestPayload,
  IBillingMethodRawBankAccount,
  IBillingMethodRawData,
  IDeleteBillingMethodRequestPayload,
  IFetchBillingMethodRequest,
  IFetchPlaidTokenRequest,
  IUpdateBillingMethodRequestPayload,
  IVerifyBillingMethodRequestPayload,
} from './types';

const BILLING_BASE_URL = `/billing/payment-methods`;

export const fetchBillingMethods = async ({ authRequestPath, roles, merchant_uuid }: IFetchBillingMethodRequest) => {
  try {
    checkBillingPermissions(roles);
    const result = await axiosInstance.post<IBillingMethodRawData>(`${BILLING_BASE_URL}/${authRequestPath}`, {
      merchant_uuid: merchant_uuid,
    });
    const data = result.data;
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new Error('Error fetching billing methods');
    }

    throw new Error('Error on requesting billing methods');
  }
};

export const addNewBillingMethod = async ({ authRequestPath, roles, data }: IAddBillingMethodRequestPayload) => {
  checkBillingPermissions(roles);

  try {
    const result = await axiosInstance.post(`${BILLING_BASE_URL}/new/${authRequestPath}`, data);
    return result.status;
  } catch (error) {
    Sentry.captureException(error);
    throw new Error('Error on adding a new billing method');
  }
};

export const updateBillingMethod = async ({
  authRequestPath,
  roles,
  payment_method_id,
  data,
}: IUpdateBillingMethodRequestPayload) => {
  checkBillingPermissions(roles);

  try {
    const result = await axiosInstance.put(`${BILLING_BASE_URL}/${payment_method_id}/${authRequestPath}`, {
      ...data,
    });
    return result.status;
  } catch (error) {
    Sentry.captureException(error);
    console.error(error);
    throw error;
  }
};

export const deleteBillingMethod = async ({
  authRequestPath,
  roles,
  payment_method_id,
  merchant_uuid,
}: IDeleteBillingMethodRequestPayload) => {
  try {
    checkBillingPermissions(roles);
    const result = await axiosInstance.delete(`${BILLING_BASE_URL}/${payment_method_id}/${authRequestPath}`, {
      data: {
        merchant_uuid: merchant_uuid,
      },
    });
    return result;
  } catch (error) {
    Sentry.captureException(error);
    console.error(error);
    throw error;
  }
};

export const fetchPlaidToken = async ({ authRequestPath, roles }: IFetchPlaidTokenRequest) => {
  checkBillingPermissions(roles);

  try {
    const result = await axiosInstance.post<IPlaidToken>(
      `${BILLING_BASE_URL}/plaid/generate_link_token/${authRequestPath}`,
      { payment_url: true },
    );
    return result.data;
  } catch (error) {
    console.error(error);
    return { link_token: '', expiration: 0 };
  }
};

export const verifyBillingPaymentMethod = async ({
  paymentMethodUuid,
  authRequestPath,
  roles,
  data,
}: IVerifyBillingMethodRequestPayload): Promise<IBillingMethodRawBankAccount> => {
  checkBillingPermissions(roles);

  try {
    const result = await axiosInstance.post(`${BILLING_BASE_URL}/verify/${paymentMethodUuid}/${authRequestPath}`, data);
    return result.data;
  } catch (error) {
    Sentry.captureException(error);
    throw new Error('Error on verifying a billing method');
  }
};
