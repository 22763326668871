import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { Box, Stack } from '@chakra-ui/react';
import { DataLoadingErrorPanel, EmptyDataAlertPanel } from '@components/errorAlert';
import { LoadingSpinner } from '@components/loadingSpinner';
import { Table } from '@components/table';
import { TabView } from '@components/tabView';

import { INITIAL_TRANSACTION_TYPE, Status, TransactionTypes } from '@/common/interfaces';
import { Verticals } from '@/common/interfaces';

import { selectActiveVertical } from '../../auth/userSlice';
import {
  selectContentStatus,
  selectPerformanceSummary,
  selectSelectedTransactionType,
  selectTransactionTypes,
  setSelectedTransactionType,
} from '../../dashboard/slice';
import { IRefinedPerformanceSummary } from '../../dashboard/types';
import { selectAllCustomersSummary } from '../../explore/customerDeepDive/slice';

import { ProfitabilityWalkthrough } from './walkthrough/ProfitabilityWalkthrough';

const STEPS_CONFIG: {
  profitStatementHighlight: { start?: number; end?: number };
  roiCalculationHighlight: { start?: number; end?: number };
}[] = [
  {
    profitStatementHighlight: {},
    roiCalculationHighlight: {},
  },
  {
    profitStatementHighlight: {
      start: 0,
      end: 0,
    },
    roiCalculationHighlight: {},
  },
  {
    profitStatementHighlight: {
      start: 0,
      end: 2,
    },
    roiCalculationHighlight: {},
  },
  {
    profitStatementHighlight: {
      start: 2,
      end: 4,
    },
    roiCalculationHighlight: {},
  },
  {
    profitStatementHighlight: {
      start: 4,
      end: 8,
    },
    roiCalculationHighlight: {},
  },
  {
    profitStatementHighlight: {},
    roiCalculationHighlight: {
      start: 0,
      end: 2,
    },
  },
];

export const Profitability = () => {
  const dispatch = useAppDispatch();
  const siteLocation = useLocation();
  const activeVertical = useAppSelector(selectActiveVertical);
  const summaryGraphData: IRefinedPerformanceSummary = useAppSelector(selectPerformanceSummary);
  const contentStatus = useAppSelector(selectContentStatus);
  const transactionTypes = useAppSelector(selectTransactionTypes);
  const allCustomersSummary = useAppSelector(selectAllCustomersSummary);
  const selectedTransactionType = useAppSelector(selectSelectedTransactionType);

  const [useLocalLoader, setUseLocalLoader] = useState(false);
  const [step, setStep] = useState(0);
  const [previouslySelectedTransactionType, setPreviouslySelectedTransactionType] = useState(INITIAL_TRANSACTION_TYPE);

  const stepConfig = STEPS_CONFIG[step];
  const { profitStatementTable, roiCalculationTable, profitabilityWalkthroughData } = summaryGraphData;

  useEffect(() => {
    if (!useLocalLoader && profitStatementTable && roiCalculationTable && profitabilityWalkthroughData) {
      setUseLocalLoader(true);
    }
  }, [profitStatementTable, roiCalculationTable, profitabilityWalkthroughData, useLocalLoader]);

  useEffect(() => {
    setPreviouslySelectedTransactionType(selectedTransactionType);

    if (activeVertical === Verticals.Fuel && /profitability/.test(siteLocation.pathname)) {
      dispatch(setSelectedTransactionType(TransactionTypes.Gas));
    }

    return () => {
      dispatch(setSelectedTransactionType(previouslySelectedTransactionType));
    };
    // (this hook should run only once and then cleanup)
  }, []);

  const handleTabChange = (tabIndex: number) => {
    const transactionType = tabIndex === 0 ? TransactionTypes.Gas : TransactionTypes.CStore;
    setStep(0);
    dispatch(setSelectedTransactionType(transactionType));
  };

  const getStatus = () => {
    return profitStatementTable &&
      roiCalculationTable &&
      profitabilityWalkthroughData &&
      useLocalLoader &&
      contentStatus === Status.Loading
      ? Status.Idle
      : contentStatus;
  };

  const onStepChange = (newStep: number): void => {
    if (STEPS_CONFIG[newStep]) {
      setStep(newStep);
    }
  };

  const content = useCallback(
    (summaryGraphData: IRefinedPerformanceSummary) => {
      return (
        <Box maxW='100%'>
          {contentStatus === Status.Loading && <LoadingSpinner />}
          {contentStatus === Status.Failed && <DataLoadingErrorPanel />}
          {contentStatus === Status.Idle && profitStatementTable?.rows?.length === 0 && <EmptyDataAlertPanel />}
          {contentStatus === Status.Idle && profitStatementTable?.rows?.length !== 0 && (
            <Stack direction={{ base: 'column', xl: 'row' }} gap={12}>
              <Box>
                <Table
                  id='profitStatementTable'
                  data={summaryGraphData.profitStatementTable}
                  highlightedRowStart={stepConfig.profitStatementHighlight.start}
                  highlightedRowEnd={stepConfig.profitStatementHighlight.end}
                />
                <Table
                  id='roiTable'
                  data={summaryGraphData.roiCalculationTable}
                  highlightedRowStart={stepConfig.roiCalculationHighlight.start}
                  highlightedRowEnd={stepConfig.roiCalculationHighlight.end}
                />
              </Box>
              {summaryGraphData.profitabilityWalkthroughData ? (
                <ProfitabilityWalkthrough
                  data={summaryGraphData.profitabilityWalkthroughData}
                  customerSummaryData={allCustomersSummary}
                  onStepChange={onStepChange}
                  step={step}
                />
              ) : (
                <DataLoadingErrorPanel />
              )}
            </Stack>
          )}
        </Box>
      );
    },
    [contentStatus, stepConfig, step, profitStatementTable, allCustomersSummary],
  );

  const tabViewContent = [
    {
      label: 'profitabilityPage.statement.fuel',
      icon: undefined,
      tabPanelContent: content,
    },
    {
      label: 'profitabilityPage.statement.cStore',
      icon: undefined,
      tabPanelContent: content,
    },
  ];

  return (
    <>
      {activeVertical !== Verticals.Fuel || (activeVertical === Verticals.Fuel && transactionTypes.length === 1) ? (
        <Box maxW='100%'>
          <Stack direction={{ base: 'column', xl: 'row' }} gap={12}>
            <Box>
              <Table
                id='profitStatementTable'
                data={profitStatementTable}
                highlightedRowStart={stepConfig.profitStatementHighlight.start}
                highlightedRowEnd={stepConfig.profitStatementHighlight.end}
              />
              <Table
                id='roiTable'
                data={roiCalculationTable}
                highlightedRowStart={stepConfig.roiCalculationHighlight.start}
                highlightedRowEnd={stepConfig.roiCalculationHighlight.end}
              />
            </Box>
            {profitabilityWalkthroughData ? (
              <ProfitabilityWalkthrough
                data={profitabilityWalkthroughData}
                customerSummaryData={allCustomersSummary}
                onStepChange={onStepChange}
                step={step}
              />
            ) : (
              <DataLoadingErrorPanel />
            )}
          </Stack>
        </Box>
      ) : (
        <TabView
          tabViewContent={tabViewContent}
          data={summaryGraphData}
          loadingStatus={getStatus()}
          onChangeTab={handleTabChange}
        />
      )}
    </>
  );
};
