/* eslint-disable @typescript-eslint/no-explicit-any */
import { IStat } from '@components/stat';
import { ITableCellValue, ITableData } from '@components/table/Table';
import { FunnelDatum } from '@nivo/funnel';
import * as Sentry from '@sentry/react';
import _ from 'lodash';

import { Verticals } from '../../common/interfaces';
import {
  buildSerieFromArray,
  display2Decimals,
  displayCurrency,
  displayPercent,
  divideIfNotZero,
  subtractIWeekOfPairs,
} from '../../common/utils';
import i18n from '../../i18n';
import {
  buildProfitabilityTable,
  buildRoiCalculationTable,
  buildRoiFlowthroughTable,
  buildWalkthroughDialogData,
} from '../reports/profitability/dataFormatting';
import { IProfitabilityFlowthrough } from '../reports/profitability/types';
import { buildOfferDistributionLine, buildOfferDistributionStats } from '../reports/promotions/dataFormatting';

import { TemporalUnit } from './slice';
import {
  IAttributionRawData,
  IAttributionSummary,
  IHomeMetricsSummary,
  IIncrementalityRawData,
  IIncrementalitySummary,
  IPerformanceRawData,
  IPerformanceSummaryRawData,
  IProfitabilityRawData,
  IProfitabilitySummary,
  IReachRawData,
  IReachSummary,
  ISitesSummary,
} from './types';

// TODO: refactor this
export const formatData = (
  rawData: IPerformanceRawData,
  temporalUnit: TemporalUnit = 'week',
  activeVertical: string,
  selectedTransactionType: string,
) => {
  let refinedData = {};

  if (rawData) {
    try {
      refinedData = {
        reachTransIncremTabs: buildReachTransactionsIncrementalityTabs(
          rawData.reach_summary,
          rawData.attribution_summary,
          rawData.incrementality_summary,
          rawData.performance_summary,
          temporalUnit,
          activeVertical,
          rawData.profitability_flowthrough,
        ),
        profitabilityStats: buildProfitabilityStats(rawData.profitability_summary, rawData.profitability_flowthrough),
        profitabilityTable: buildProfitabilityTable(rawData.profitability_flowthrough),
        profitStatementTable: buildProfitStatement(
          rawData.profitability_flowthrough,
          activeVertical,
          selectedTransactionType,
        ),
        roiFlowthroughTable: buildRoiFlowthroughTable(rawData.profitability_flowthrough),
        roiCalculationTable: buildRoiCalculationTable(rawData.profitability_flowthrough),
        profitabilityWalkthroughData: buildWalkthroughDialogData(rawData.profitability_flowthrough),
        offerDistribution: {
          stats: buildOfferDistributionStats(rawData.offer_distribution, activeVertical, selectedTransactionType),
          lineGraph: buildOfferDistributionLine(
            rawData.offer_distribution.avg_accepted_offers_over_time,
            temporalUnit,
            activeVertical,
          ),
        },
        homeMetricsData: buildHomeMetricsSummary(
          rawData.profitability_flowthrough,
          rawData.profitability_summary,
          rawData.site_summaries,
          rawData.performance_summary,
          selectedTransactionType,
        ),
      };
    } catch (error) {
      Sentry.captureException(error);
      console.error('Error: ', error);
    }
  } else {
    throw Error('empty result');
  }
  return refinedData;
};

export const buildReachStats = (data: IReachRawData): IReachSummary => {
  // const offersSeen = buildSerieFromArray('Users seeing offers', data.offers_seen);
  // const offersClaimed = buildSerieFromArray('Unique users claiming offers', data.offers_claimed);

  const usersSeeing = data.unique_users_seeing_total as number;
  const usersClaiming = data.unique_users_claiming_total as number;
  const usersTransacting = data.unique_users_transacting_total as number;

  const funnelGraphData: FunnelDatum[] = [
    {
      id: '100%',
      value: usersSeeing,
      label: 'Unique users seeing offers',
    },
    {
      id: displayPercent(divideIfNotZero(usersClaiming, usersSeeing)),
      value: usersClaiming,
      label: 'Unique users claiming offers',
    },
    {
      id: displayPercent(divideIfNotZero(usersTransacting, usersClaiming)),
      value: usersTransacting,
      label: 'Unique users transacting',
    },
  ];

  const statCards: Array<IStat> = [
    {
      label: i18n.t('homePage.programBasics.reachTab.offersSeen.label') as string,
      value: data.offers_seen, // offersSeen.total,
      help: i18n.t('homePage.programBasics.reachTab.offersSeen.tooltip') as string,
    },
    // {
    //   label: 'Unique Users Seeing Offers',
    //   value: uniqueUsers.total,
    //   help: 'Unique users who saw an Upside offer in the Upside map during the selected date range',
    // },
    {
      label: i18n.t('homePage.programBasics.reachTab.offersClaimed.label') as string,
      value: data.offers_claimed, // offersClaimed.total,
      help: i18n.t('homePage.programBasics.reachTab.offersClaimed.tooltip') as string,
    },
  ];

  const resultObject: IReachSummary = {
    statCards: statCards,
    // reachGraph: [offersSeen.serie, offersClaimed.serie],
    funnelGraph: funnelGraphData,
    // totalUniqueUsersOffersSeen: usersSeeing,
    // totalOffersClaimed: usersClaiming,
    // totalOffersSeen: offersSeen.total,
  };

  return resultObject;
};

export const buildTransactionsStats = (
  data: IAttributionRawData,
  performanceSummary: IPerformanceSummaryRawData,
  temporalUnit: TemporalUnit,
  activeVertical: string,
  profitData: IProfitabilityFlowthrough,
): IAttributionSummary => {
  const totalUpsideSales = Number(profitData.total_upside_revenue);

  const upsideTransactions = buildSerieFromArray('Upside transactions', data.total_transactions, temporalUnit);
  const uniqueUsersTransacting = buildSerieFromArray(
    'Unique users transacting',
    data.unique_users_transacting,
    temporalUnit,
  );
  const totalUpsideRevenue = buildSerieFromArray('Total Upside Revenue', data.total_upside_revenue, temporalUnit);

  const statCards: Array<IStat> = [
    {
      label: i18n.t('homePage.programBasics.transactionsTab.transactions.label') as string,
      value: performanceSummary.total_tx,
      help: i18n.t('homePage.programBasics.transactionsTab.transactions.tooltip') as string,
    },
    // {
    //   label: 'Unique Users Transacting',
    //   value: uniqueUsersTransacting.total,
    //   help: 'Unique users making a transaction with an Upside offer during the selected date range. Note: since users can transact across multiple weeks, this will not be the sum across the data over time below',
    // },
    {
      label: i18n.t('homePage.programBasics.transactionsTab.salesOnUpside.label') as string,
      value: `${displayCurrency(totalUpsideSales, 0)}`,
      help: i18n.t(`homePage.programBasics.transactionsTab.salesOnUpside.${activeVertical}.tooltip`) as string,
    },
  ];

  const resultObject = {
    statCards: statCards,
    txUsersGraph: [uniqueUsersTransacting.serie, upsideTransactions.serie],
    totalRevenueGraph: [totalUpsideRevenue.serie],
    totalUniqueUsersTransacting: uniqueUsersTransacting.total,
    totalUpsideRevenue: totalUpsideRevenue.total,
    totalUpsideTransactions: performanceSummary.total_tx,
  };

  return resultObject;
};

export const buildIncrementalityStats = (
  data: IIncrementalityRawData,
  performanceSummary: IPerformanceSummaryRawData,
  temporalUnit: TemporalUnit,
  profitData: IProfitabilityFlowthrough,
): IIncrementalitySummary => {
  let resultObject: IIncrementalitySummary = {
    statCards: [],
    incrementalGraph: [],
    totalIncrementalRevenue: 0,
  };

  if (!_.isEmpty(data.total_upside_revenue) && !_.isEmpty(data.total_incremental_revenue)) {
    const nonincrementalSales = buildSerieFromArray(
      'Non-incremental sales',
      subtractIWeekOfPairs(data.total_upside_revenue, data.total_incremental_revenue),
      temporalUnit,
    );
    const incrementalRevenue = buildSerieFromArray('Incremental sales', data.total_incremental_revenue, temporalUnit);
    const statCards: Array<IStat> = [
      {
        label: i18n.t('homePage.programBasics.incrementalityTab.incrementalSales.label') as string,
        value: `${displayCurrency(profitData.incremental_revenue, 0)}`,
        help: i18n.t('homePage.programBasics.incrementalityTab.incrementalSales.tooltip') as string,
      },
      {
        label: i18n.t('homePage.programBasics.incrementalityTab.avgIncrementalRate.label') as string,
        value: `${displayPercent(
          divideIfNotZero(performanceSummary.total_incremental_revenue, performanceSummary.total_revenue),
        )}`,
        help: i18n.t('homePage.programBasics.incrementalityTab.avgIncrementalRate.tooltip') as string,
      },
      // {
      //   label: 'Total Upside Revenue',
      //   value: `${displayCurrency(upsideRevenue.total)}`,
      //   help: 'Total sales associated with Upside transactions during the selected date range',
      // },
      // {
      //   label: 'Expected Revenue from Control',
      //   value: `${displayCurrency(upsideRevenue.total - incrementalRevenue.total)}`,
      //   help: "Proportion of Upside total revenue that was expected without Upside, calculated using the control group matched to each user's card",
      // },
    ];

    resultObject = {
      statCards: statCards,
      incrementalGraph: [nonincrementalSales.serie, incrementalRevenue.serie],
      totalIncrementalRevenue: incrementalRevenue.total,
    };
  }

  return resultObject;
};

const buildReachTransactionsIncrementalityTabs = (
  reachData: IReachRawData,
  transactionsData: IAttributionRawData,
  incrementalityData: IIncrementalityRawData,
  performanceSummary: IPerformanceSummaryRawData,
  temporalUnit: TemporalUnit,
  activeVertical: string,
  profitData: IProfitabilityFlowthrough,
) => {
  const refinedTransactionsData = buildTransactionsStats(
    transactionsData,
    performanceSummary,
    temporalUnit,
    activeVertical,
    profitData,
  );
  const refinedReachData = buildReachStats(reachData);
  const refinedIncrementalityData = buildIncrementalityStats(
    incrementalityData,
    performanceSummary,
    temporalUnit,
    profitData,
  );

  return {
    reachStats: refinedReachData,
    transactionsStats: refinedTransactionsData,
    incrementalityStats: refinedIncrementalityData,
  };
};

export const buildProfitabilityStats = (
  profitabilityData: IProfitabilityRawData,
  flowthroughData: IProfitabilityFlowthrough,
): IProfitabilitySummary => {
  const statCards: Array<IStat> = [
    {
      label: i18n.t('homePage.programBasics.profitTab.roi.label') as string,
      value: `${displayPercent(profitabilityData.overall_roi ? profitabilityData.overall_roi : 0)}`,
      help: i18n.t('homePage.programBasics.profitTab.roi.tooltip') as string,
    },
    {
      label: i18n.t('homePage.programBasics.profitTab.incrementalNetProfit.label') as string,
      value: `${displayCurrency(flowthroughData.incremental_net_profit, 0)}`,
      help: i18n.t('homePage.programBasics.profitTab.incrementalNetProfit.tooltip') as string,
    },
    // {
    //   label: 'Total Charges (Promotions + Profit Share)',
    //   value: `${displayCurrency(totalCharges.total)}`,
    //   help: 'Total amount of promotions and profit share paid during the selected period in order to generate incremental net profit via Upside',
    // },
  ];

  const resultObject = {
    statCards: statCards,
  };

  return resultObject;
};

export const buildProfitabilityTabTable = (data: IProfitabilityFlowthrough): ITableData => {
  const rows: Array<string | number>[] = [
    ['Total Upside Revenue', '', `${displayCurrency(data.total_upside_revenue)}`],
    ['Incremental Revenue', '', `${displayCurrency(data.incremental_revenue)}`],
    ['Average Gross Margin', 'x', `${displayPercent(data.average_gross_margin)}`],
    ['Incremental Gross Profit', '=', `${displayCurrency(data.incremental_gross_profit)}`],
    ['Cost of Customer Promotions', '-', `${displayCurrency(data.cash_back_provided_to_customers)}`],
    ['Upside Profit Share', '-', `${displayCurrency(data.upside_profit_share)}`],
    ['Incremental Net Profit from Upside', '=', `${displayCurrency(data.incremental_net_profit_from_upside)}`],
  ];

  const result: ITableData = {
    title: 'Profit Statement',
    headers: [],
    rows: rows,
  };

  return result;
};

export const buildProfitStatement = (
  data: IProfitabilityFlowthrough,
  activeVertical: string,
  selectedTransactionType: string,
): ITableData => {
  let rows: Array<string | number | ITableCellValue>[] = [];
  const isEmptyData = Boolean(
    !data.total_upside_revenue && !data.cash_back_provided_to_customers && !data.incremental_gross_profit,
  );

  if (!isEmptyData) {
    rows = [
      (activeVertical === Verticals.Fuel && selectedTransactionType === 'allTransactions') ||
      (activeVertical === Verticals.Fuel && selectedTransactionType === 'GAS')
        ? [
            i18n.t('dashboardPage.profitStatementTbl.gallonsOnUpside') as string,
            '',
            {
              value: display2Decimals(data.total_gallons_bought, 0),
              isNumeric: true,
              tableCellId: 'gallonsOnUpside',
            },
          ]
        : [
            i18n.t('dashboardPage.profitStatementTbl.salesOnUpside') as string,
            '',
            {
              value: displayCurrency(data.total_upside_revenue),
              isNumeric: true,
              tableCellId: 'salesOnUpside',
            },
          ],
      (activeVertical === Verticals.Fuel && selectedTransactionType === 'allTransactions') ||
      (activeVertical === Verticals.Fuel && selectedTransactionType === 'GAS')
        ? [
            i18n.t('dashboardPage.profitStatementTbl.nonIncrementalGallons') as string,
            '-',
            {
              value: display2Decimals(data.non_incremental_gallons, 0),
              isNumeric: true,
              tableCellId: 'nonIncrementalGallons',
            },
          ]
        : [
            i18n.t('dashboardPage.profitStatementTbl.nonincrementalSales') as string,
            '-',
            {
              value: displayCurrency(data.expected_revenue_from_control),
              isNumeric: true,
              tableCellId: 'nonincrementalSales',
            },
          ],
      (activeVertical === Verticals.Fuel && selectedTransactionType === 'allTransactions') ||
      (activeVertical === Verticals.Fuel && selectedTransactionType === 'GAS')
        ? [
            i18n.t('dashboardPage.profitStatementTbl.totalIncrementalGallons') as string,
            '=',
            {
              value: display2Decimals(data.total_incremental_gallons, 0),
              isNumeric: true,
              tableCellId: 'totalIncrementalGallons',
            },
          ]
        : [
            i18n.t('dashboardPage.profitStatementTbl.incrementalSales') as string,
            '=',
            {
              value: displayCurrency(data.incremental_revenue),
              isNumeric: true,
              tableCellId: 'incrementalSales',
            },
          ],
      (activeVertical === Verticals.Fuel && selectedTransactionType === 'allTransactions') ||
      (activeVertical === Verticals.Fuel && selectedTransactionType === 'GAS')
        ? [
            i18n.t('dashboardPage.profitStatementTbl.blendedAvgMarginPerGallon') as string,
            '\u00D7',
            {
              value: displayCurrency(data.blended_average_margin_gallon, 4),
              isNumeric: true,
              tableCellId: 'blendedAvgMarginPerGallon',
            },
          ]
        : [
            i18n.t('dashboardPage.profitStatementTbl.avgMargin') as string,
            '\u00D7',
            {
              value: displayPercent(data.average_gross_margin),
              isNumeric: true,
              tableCellId: 'avgMargin',
            },
          ],
      [
        i18n.t('dashboardPage.profitStatementTbl.incrementalProfit') as string,
        '=',
        {
          value: displayCurrency(data.incremental_gross_profit),
          isNumeric: true,
          tableCellId: 'incrementalProfit',
        },
      ],
      [
        i18n.t('dashboardPage.profitStatementTbl.totalCustomerPromos') as string,
        '-',
        {
          value: displayCurrency(data.cash_back_provided_to_customers),
          isNumeric: true,
          tableCellId: 'totalCustomerPromos',
        },
      ],
      [
        i18n.t('dashboardPage.profitStatementTbl.upsideFundedPromotions') as string,
        '+',
        {
          value: displayCurrency(data.upside_funded_customer_promotions),
          isNumeric: true,
          tableCellId: 'upsideFundedPromotions',
        },
      ],
      [
        i18n.t('dashboardPage.profitStatementTbl.upsideProfitShare') as string,
        '-',
        {
          value: displayCurrency(data.upside_profit_share),
          isNumeric: true,
          tableCellId: 'upsideProfitShare',
        },
      ],
      [
        i18n.t('dashboardPage.profitStatementTbl.incrementalNetProfit') as string,
        '=',
        {
          value: displayCurrency(data.incremental_net_profit_from_upside),
          isNumeric: true,
          tableCellId: 'incrementalNetProfit',
        },
      ],
    ];
  }

  const result: ITableData = {
    title: i18n.t('dashboardPage.profitStatementTbl.title'),
    columnWidths: ['', '30px', '150px'],
    headers: [],
    rows: rows,
  };

  return result;
};

export const buildHomeMetricsSummary = (
  profit_data: IProfitabilityFlowthrough,
  profit_summary: IProfitabilityRawData,
  sites_summary: ISitesSummary,
  performance_summary: IPerformanceSummaryRawData,
  selectedTransactionType: string,
): IHomeMetricsSummary => {
  const totalUpsideRevenue = Number(profit_data.total_upside_revenue);
  const total_sales = sites_summary.total_sites_revenue;
  const incrementalSales = Number(profit_data.incremental_revenue);
  const totalProgramCosts = Number(profit_data.total_promotions_and_profit_share);
  const customerProgramCosts = Number(
    performance_summary.customer_promotion_cost - performance_summary.upside_funded_customer_promotion,
  );
  const incrementalNetProfit = Number(profit_data.incremental_net_profit);
  const overall_roi = Number(profit_summary.overall_roi);
  const isEmptyData =
    _.isEmpty(sites_summary) && !profit_summary.overall_roi && _.every(profit_data, (value) => value === 0);

  return {
    salesOnUpside: {
      value: isEmptyData ? '-' : displayCurrency(totalUpsideRevenue, 0),
      ...(selectedTransactionType !== 'CONVENIENCE_STORE'
        ? {
            altValue:
              totalUpsideRevenue > 0 && total_sales > 0 ? displayPercent(totalUpsideRevenue / total_sales) : '-%',
          }
        : {}),
    },
    incrementalSales: {
      value: isEmptyData ? '-' : displayCurrency(incrementalSales, 0),
      ...(selectedTransactionType !== 'CONVENIENCE_STORE'
        ? {
            altValue: totalUpsideRevenue > 0 && total_sales > 0 ? displayPercent(incrementalSales / total_sales) : '-%',
          }
        : {}),
    },
    totalProgramCosts: {
      value: isEmptyData ? '-' : displayCurrency(totalProgramCosts, 0),
      altValue: isEmptyData ? '-%' : totalUpsideRevenue ? displayPercent(totalProgramCosts / totalUpsideRevenue) : 0,
    },
    customerProgramCosts: {
      value: isEmptyData ? '-' : displayCurrency(totalProgramCosts, 0),
      altValue: isEmptyData ? '-' : customerProgramCosts ? displayCurrency(customerProgramCosts, 0) : 0,
    },
    incrementalNetProfit: {
      value: isEmptyData ? '-' : displayCurrency(incrementalNetProfit, 0),
      altValue: isEmptyData ? '-%' : overall_roi ? displayPercent(overall_roi) : 0,
    },
    roi: {
      value: isEmptyData ? '-%' : overall_roi ? displayPercent(overall_roi) : 0,
      altValue: isEmptyData ? '-' : displayCurrency(incrementalNetProfit, 0),
    },
  };
};
