/* eslint-disable @typescript-eslint/no-explicit-any */
import { t } from 'i18next';

import { displayCurrency } from '@/common/utils';
import { compactLargeNumber, displayNumberWithDecimals } from '@/common/utils/utils';
import { BehaviorWalkthroughState } from '@/features/dashboard/slice';
import themeColors from '@/theme/foundations/colors';

export const prePostWalkthroughDataFormatter = (
  selectedData: any,
  behaviorWalkthroughState: BehaviorWalkthroughState,
) => {
  if (behaviorWalkthroughState) {
    if (behaviorWalkthroughState.step <= 1 || behaviorWalkthroughState.step === 3) return selectedData;
    if (behaviorWalkthroughState.step === 2) {
      return {
        '0': {
          id: selectedData[0].id,
          data: [
            {
              [`${t('behaviorPage.dataLabels.similarCustomer')}`]: selectedData[0].data[0]['Similar Customer'],
              [`${t('behaviorPage.dataLabels.upsideCustomer')}`]: selectedData[0].data[0]['Upside Customer'],
              label: `${t('behaviorPage.dataLabels.beforeUpside')}`,
            },
            {
              [`${t('behaviorPage.dataLabels.similarCustomer')}`]: null,
              [`${t('behaviorPage.dataLabels.upsideCustomer')}`]: null,
              label: `${t('behaviorPage.dataLabels.afterUpside')}`,
            },
          ],
        },
      };
    }
    if (behaviorWalkthroughState.step >= 4) {
      return {
        '0': {
          id: selectedData[0].id,
          data: [
            {
              [`${t('behaviorPage.dataLabels.upsideCustomer')}`]: selectedData[0].data[0]['Upside Customer'],
              label: `${t('behaviorPage.dataLabels.beforeUpside')}`,
            },
            {
              [`${t('behaviorPage.dataLabels.upsideCustomer')}`]: selectedData[0].data[1]['Upside Customer'],
              label: `${t('behaviorPage.dataLabels.afterUpside')}`,
            },
          ],
        },
      };
    }

    return {};
  }
};

export const prePostChartProps = (
  behaviorWalkthroughState: BehaviorWalkthroughState,
  FormattedLabels: (props: any) => JSX.Element[],
  colors: Record<string, string>,
  selectedValueType: string,
) => {
  const chartProps = {
    groupMode: 'grouped',
    legends: [
      {
        dataFrom: 'keys',
        anchor: 'bottom',
        direction: 'row',
        justify: false,
        translateY: 50,
        itemsSpacing: 150,
        itemWidth: 10,
        itemHeight: 20,
        itemDirection: 'left-to-right',
        itemOpacity: 0.85,
        symbolSize: 12,
        symbolShape: 'circle',
      },
    ],
    colors: (value: { id: string }) => colors[value.id] || themeColors.neutral[1200],
    valueFormat: (value: any) =>
      selectedValueType === 'sales' ? displayCurrency(value) : displayNumberWithDecimals(value as number),
    layers: ['legends', 'grid', 'bars', 'axes', FormattedLabels, 'markers', 'annotations'],
    axisLeft: null,
  };

  if (behaviorWalkthroughState && behaviorWalkthroughState.step === 0) {
    return chartProps;
  } else if (behaviorWalkthroughState && behaviorWalkthroughState.step === 1) {
    chartProps.layers = ['grid', 'bars', 'markers', 'annotations'];
  } else if (behaviorWalkthroughState && behaviorWalkthroughState.step >= 4) {
    chartProps.layers = ['legends', 'grid', 'bars', 'axes', 'markers', 'annotations'];
  }
  return chartProps;
};

export const onOffChartProps = (behaviorWalkthroughState: BehaviorWalkthroughState, FormattedLabels: any) => {
  const chartProps = {
    colors: ['#2DC48E', '#96E1C6'],
    groupMode: 'stacked',
    layers: ['legends', 'grid', 'bars', 'axes', FormattedLabels, 'markers', 'annotations'],
    layout: 'vertical',
    legends: [
      {
        dataFrom: 'keys',
        anchor: 'bottom',
        direction: 'row',
        justify: false,
        translateY: 60,
        itemsSpacing: 100,
        itemWidth: 10,
        itemHeight: 20,
        itemDirection: 'left-to-right',
        itemOpacity: 0.85,
        symbolSize: 12,
        symbolShape: 'circle',
      },
    ],
    padding: 0.33,
    axisLeft: null,
    enableLabel: false,
    valueFormat: (value: any) => compactLargeNumber(value),
  };
  if (behaviorWalkthroughState && behaviorWalkthroughState.step === 6) {
    chartProps.layers = ['grid', 'bars', 'axes', FormattedLabels, 'markers', 'annotations'];
  } else if (behaviorWalkthroughState && behaviorWalkthroughState.step === 7) {
    return Object.assign(chartProps, {
      enableLabel: true,
      layers: ['legends', 'grid', 'bars', 'axes', 'markers', 'annotations'],
      label: (d: { value: string | number }) => compactLargeNumber(d.value as number),
    });
  } else if (behaviorWalkthroughState && behaviorWalkthroughState.step >= 8) {
    return Object.assign(chartProps, {
      colors: ['rgb(45, 196, 142, 0.3)', '#96E1C6'],
      enableLabel: true,
      layers: ['legends', 'grid', 'bars', 'axes', 'markers', 'annotations'],
      label: (d: { value: string | number }) => compactLargeNumber(d.value as number),
      labelTextColor: (d: { data: { id: string } }) => {
        return d.data.id === 'Non-Upside' ? `rgb(0, 0, 0, 0.3)` : `rgb(0, 0, 0)`;
      },
    });
  }

  return chartProps;
};

export const onOffWalkthroughDataFormatter = (
  selectedData: any,
  behaviorWalkthroughState: BehaviorWalkthroughState,
) => {
  if (behaviorWalkthroughState) {
    if (selectedData && behaviorWalkthroughState.step < 5) return selectedData;
    if (behaviorWalkthroughState && behaviorWalkthroughState.step === 5) {
      return {
        '0': {
          id: selectedData[0].id,
          data: [
            {
              [`${t('behaviorPage.dataLabels.nonUpside')}`]: selectedData[0].data[0]['Non-Upside'],
              label: `${t('behaviorPage.dataLabels.beforeUpside')}`,
            },
          ],
        },
      };
    }
    if (behaviorWalkthroughState && behaviorWalkthroughState.step === 6) {
      return {
        '0': {
          id: selectedData[0].id,
          data: [
            {
              [`${t('behaviorPage.dataLabels.nonUpside')}`]: selectedData[0].data[0]['Non-Upside'],
              label: `${t('behaviorPage.dataLabels.beforeUpside')}`,
            },
            {
              'Non-Upside': selectedData[0].data[1]['Non-Upside'] + selectedData[0].data[1]['Upside sales'],
              label: `${t('behaviorPage.dataLabels.afterUpside')}`,
            },
          ],
        },
      };
    }
    if (behaviorWalkthroughState && behaviorWalkthroughState.step === 7) {
      const formattedData = {
        '0': {
          id: selectedData[0].id,
          data: [
            {
              [`${t('behaviorPage.dataLabels.nonUpside')}`]: selectedData[0].data[0]['Non-Upside'],
              [`${t('behaviorPage.dataLabels.upsideSales')}`]: null,
              label: `${t('behaviorPage.dataLabels.beforeUpside')}`,
            },
            {
              [`${t('behaviorPage.dataLabels.nonUpside')}`]: selectedData[0].data[1]['Non-Upside'],
              [`${t('behaviorPage.dataLabels.upsideSales')}`]: selectedData[0].data[1]['Upside sales'],
              label: `${t('behaviorPage.dataLabels.afterUpside')}`,
            },
          ],
        },
      };

      return formattedData;
    }
    if (behaviorWalkthroughState && behaviorWalkthroughState.step >= 8) {
      const formattedData = {
        '0': {
          id: selectedData[0].id,
          data: [
            {
              [`${t('behaviorPage.dataLabels.nonUpside')}`]: selectedData[0].data[0]['Non-Upside'],
              [`${t('behaviorPage.dataLabels.upsideSales')}`]: null,
              label: `${t('behaviorPage.dataLabels.beforeUpside')}`,
            },
            {
              [`${t('behaviorPage.dataLabels.nonUpside')}`]: selectedData[0].data[1]['Non-Upside'],
              [`${t('behaviorPage.dataLabels.upsideSales')}`]: selectedData[0].data[1]['Upside sales'],
              label: `${t('behaviorPage.dataLabels.afterUpside')}`,
            },
          ],
        },
      };
      return formattedData;
    }

    return {};
  }
};

export const isDisabled = (step: number, behaviorWalkthroughState: BehaviorWalkthroughState) => {
  return behaviorWalkthroughState && behaviorWalkthroughState.step >= step ? true : false;
};
