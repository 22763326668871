import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '@/app/store';
import { Status } from '@/common/interfaces';

import { IPlaidToken } from '../hooks';

import {
  AddBillingMethodFormType,
  IBillingMethodRawData,
  IBillingMethodVerificationSuccessPayloadAction,
  IVerifyBillingMethodRequestPayload,
} from './types';

export interface IBillingState {
  billingPlaidToken: IPlaidToken;
  error?: string;
  status: Status;
  methods: IBillingMethodRawData;
  newBillingMethod?: AddBillingMethodFormType;
}

export const initialState: IBillingState = {
  billingPlaidToken: { link_token: '', expiration: 0 },
  status: Status.Idle,
  methods: { bank_accounts: [], cards: [] },
  newBillingMethod: undefined,
};

export const billingPaymentMethodsSlice = createSlice({
  name: 'billingPaymentMethods',
  initialState,
  reducers: {
    setBillingLoadingState: (state, action: PayloadAction<Status>) => {
      state.status = action.payload;
    },
    setPlaidToken: (state, action: PayloadAction<IPlaidToken>) => {
      state.billingPlaidToken = action.payload;
    },
    setNewBillingMethodData: (state, action: PayloadAction<AddBillingMethodFormType>) => {
      state.newBillingMethod = action.payload;
    },
    billingPlaidTokenFetchRequest: (state) => {
      state.error = undefined;
      state.status = Status.Loading;
    },
    billingPlaidTokenSucceeded: (state, action: PayloadAction<{ token_data: IPlaidToken }>) => {
      state.billingPlaidToken = action.payload.token_data;
      state.status = Status.Idle;
    },
    billingPlaidTokenFailed: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.status = Status.Failed;
    },
    billingPaymentMethodsFetchRequest: (state) => {
      state.error = undefined;
      state.status = Status.Loading;
    },
    billingPaymentMethodsSucceeded: (state, action: PayloadAction<IBillingMethodRawData>) => {
      state.methods = action.payload;
      state.status = Status.Idle;
    },
    billingPaymentMethodsFailed: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.status = Status.Failed;
    },
    addNewBillingMethodsRequest: (state) => {
      state.error = undefined;
      state.status = Status.Loading;
    },
    addNewBillingMethodsSucceeded: (state) => {
      state.status = Status.Idle;
    },
    addNewBillingMethodsFailed: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.status = Status.Failed;
    },
    updateBillingMethodRequest: (state) => {
      state.error = undefined;
      state.status = Status.Loading;
    },
    updateBillingMethodSucceeded: (state) => {
      state.newBillingMethod = undefined;
    },
    updateBillingMethodFailed: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.status = Status.Failed;
    },
    deleteBillingMethodRequest: (state) => {
      state.error = undefined;
      state.status = Status.Loading;
    },
    deleteBillingMethodSucceeded: (state) => {
      state.newBillingMethod = undefined;
    },
    deleteBillingMethodFailed: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.status = Status.Failed;
    },
    verifyBillingPaymentMethodSucceeded: (
      state,
      action: PayloadAction<IBillingMethodVerificationSuccessPayloadAction>,
    ) => {
      const { paymentMethodUuid, verifiedStripeBankAccount } = action.payload;
      const bankAccounts = state.methods.bank_accounts.map((account) => {
        if (account.id === paymentMethodUuid) {
          return verifiedStripeBankAccount;
        }
        if (verifiedStripeBankAccount.isDefault) {
          account.isDefault = false;
        }
        return account;
      });
      const cards = state.methods.cards.map((card) => {
        if (verifiedStripeBankAccount.isDefault) {
          card.isDefault = false;
        }
        return card;
      });
      state.methods.bank_accounts = bankAccounts;
      state.methods.cards = cards;
      state.status = Status.Idle;
    },
    verifyBillingPaymentMethodFailed: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.status = Status.Failed;
    },
  },
});

export const {
  setBillingLoadingState,
  setPlaidToken,
  setNewBillingMethodData,
  billingPlaidTokenFetchRequest,
  billingPlaidTokenSucceeded,
  billingPlaidTokenFailed,
  billingPaymentMethodsFetchRequest,
  billingPaymentMethodsSucceeded,
  billingPaymentMethodsFailed,
  addNewBillingMethodsRequest,
  addNewBillingMethodsSucceeded,
  addNewBillingMethodsFailed,
  updateBillingMethodRequest,
  updateBillingMethodSucceeded,
  updateBillingMethodFailed,
  deleteBillingMethodRequest,
  deleteBillingMethodSucceeded,
  deleteBillingMethodFailed,
  verifyBillingPaymentMethodSucceeded,
  verifyBillingPaymentMethodFailed,
} = billingPaymentMethodsSlice.actions;

export const verifyBillingPaymentMethodRequest = createAction<IVerifyBillingMethodRequestPayload>(
  'verifyBillingPaymentMethodRequest',
);
export const selectBillingMethods = (state: RootState) => state.billing.methods;
export const selectBillingLoadingStatus = (state: RootState) => state.billing.status;
export const selectBillingError = (state: RootState) => state.payment.error;
export const selectBillingPlaidToken = (state: RootState) => state.billing.billingPlaidToken;

export default billingPaymentMethodsSlice.reducer;
