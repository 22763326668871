import { useMemo } from 'react';
import { Alert, AlertDescription, AlertIcon, AlertTitle, CloseButton, Flex, Link } from '@chakra-ui/react';
import { AnimatePresence, motion } from 'framer-motion';

import { useAppDispatch, useAppSelector } from '@/app/hooks';

import {
  closeSunocoBanner,
  selectHasUserClosedSunocoBanner,
  selectLocationsFilter,
  selectSunocoSites,
} from '../../slice';

export function SunocoBanner() {
  const dispatch = useAppDispatch();
  const locations = useAppSelector(selectLocationsFilter);
  const sunocoSites = useAppSelector(selectSunocoSites);
  const hasUserClosedSunocoBanner = useAppSelector(selectHasUserClosedSunocoBanner);

  const shouldShowBanner = useMemo(() => {
    const siteUUIDs: Set<string> = new Set(sunocoSites);

    return !hasUserClosedSunocoBanner && locations.some(({ site_uuid }) => siteUUIDs.has(site_uuid));
  }, [sunocoSites, hasUserClosedSunocoBanner, locations]);

  const closeBanner = () => {
    dispatch(closeSunocoBanner());
  };

  return shouldShowBanner ? (
    <AnimatePresence>
      <Alert
        as={motion.div}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        status='info'
        alignItems='flex-start'
        mb={10}
        bgColor='background.primary.default'
      >
        <AlertIcon color='white' />
        <Flex direction='column' color='white'>
          <AlertTitle fontSize='lg'>Welcome to your Upside dashboard</AlertTitle>
          <AlertDescription>
            This dashboard is your go-to resource for real-time key metrics and program performance including gallons,
            profit, new customers, and more! We encourage you to take a look around. If you have any questions or would
            like to learn more, please visit the{' '}
            <Link color='text.default-inverse' href='https://try.upside.com/sunoco-home' textDecoration={'underline'}>
              Upside Partnership Hub
            </Link>
            , reach out to your Upside Account Manager, or email{' '}
            <Link color='text.default-inverse' href='mailto:sunoco@upside.com'>
              sunoco@upside.com
            </Link>
            .
          </AlertDescription>
        </Flex>
        <CloseButton
          onClick={closeBanner}
          color='white'
          alignSelf='flex-start'
          position='relative'
          right={-1}
          top={-1}
        />
      </Alert>
    </AnimatePresence>
  ) : null;
}
