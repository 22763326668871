import { ITableData } from '@/common/components/table';
import { TransactionTypes, Verticals } from '@/common/interfaces';
import { TemporalUnit } from '@/features/dashboard/slice';

export interface ICSVData {
  [key: string]: string | number;
}

export interface ISitePerformance {
  uuid: string;
  latitude: number;
  longitude: number;
  name: string;
  address: string;
  totalRevenue: number;
  totalSiteRevenue: number;
  totalTransactions: number;
  totalGallonsBought?: number;
  totalCustomerPromotions: number;
  percentOfCardSales?: number;
  percentOfLocationSales?: number;
  incrementalGallons?: number;
  exclusivityZone?: number;
  competitors?: number;
  incrementalRevenue: number;
}

export interface ISitePerformanceStats {
  sites: ISitePerformance[];
}

export interface ISiteSummariesRawData {
  uuid: string;
  name: string;
  network_display_name: string;
  address: string;
  geolocation: {
    latitude: string | number;
    longitude: string | number;
  };
  total_upside_tx: string | number;
  total_upside_revenue: string | number;
  total_site_revenue: string | number;
  total_gallons_bought: string | number;
  total_customer_promotion_cost: string | number;
  incremental_gallons: string | number;
  incremental_revenue: string | number;
  exclusivity_zone: number;
}

export interface ISiteLevelPerformance {
  table: ITableData;
  topSitesTable: ITableData;
  topSitesLocationTable: ITableData;
  topSitesLocationCSVData: ICSVData[];
  map: ISitePerformanceStats;
}

export interface ILocationsRequestPayload {
  startDate: string;
  endDate: string;
  temporalUnit: TemporalUnit;
  selectedNetworks: string[];
  selectedTransactionType: TransactionTypes;
  authRequestPath: string;
  activeVertical: Verticals;
  networkNames: string[];
  locations: string[];
  transactionType: TransactionTypes;
}

export enum MapVisualizations {
  Competitors = 'competitors',
  HeatMap = 'heat map',
}

export enum CompetitorTypes {
  BLOCKED_COMPETITORS = 'BLOCKED_COMPETITORS',
  UPSIDE = 'UPSIDE',
  COMPETITORS = 'COMPETITORS',
}

export interface ICompetitor {
  site_brand: string;
  address: string;
  geolocation: {
    latitude: number;
    longitude: number;
  };
  postal_code?: string;
  type: CompetitorTypes;
}

export interface ICompetitorsRequestPayload {
  authRequestPath: string;
  locations: string[];
  competitorType: string[];
}
