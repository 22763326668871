import { useState } from 'react';
import { InfoIcon } from '@chakra-ui/icons';
import { ChakraProps, ChakraStyledOptions, Flex, Popover, PopoverAnchor, Td } from '@chakra-ui/react';

import { ICustomerTransactionMarginDetail } from '@/features/explore/customerDeepDive/types';
import { MarginDetailPopover } from '@/features/reports/customers/MarginDetailPopover';
import { OutlierInfo } from '@/features/reports/customers/OutlierInfo';
import {
  DownloadInvoiceLink,
  DownloadInvoicePopover,
} from '@/features/reports/financialReports/components/DownloadPopover';

import { EMPTY_CELL_VALUE } from '../../utils';

import { ITableCellValue } from './Table';

export function TableCell({
  column,
  colIdx,
  columnWidths,
  tdStyles,
}: {
  column: string | number | ITableCellValue;
  colIdx: number;
  columnWidths: (string | number | undefined)[];
  tdStyles: ChakraProps;
}) {
  const [isOpen, setIsOpen] = useState(false);
  if (!column) {
    return <Td {...tdStyles} />;
  }

  const getContent = () => {
    if (typeof column === 'object' && column?.popoverData)
      return <MarginDetailPopover data={column.popoverData as ICustomerTransactionMarginDetail} />;
    if (typeof column === 'object' && column?.outlierInfo)
      return <OutlierInfo outlierInfo={column.outlierInfo as string} />;
    if (typeof column === 'object' && column?.popoverLink)
      return <DownloadInvoicePopover uuid={column.invoiceUuid as string} />;
    return null;
  };

  const hasContent =
    typeof column === 'object' && (!!column?.popoverData || !!column?.outlierInfo || column?.popoverLink); // Combined check for content

  const hasInfoIcon = typeof column === 'object' && (!!column?.outlierInfo || column.popoverData);
  const hasDownloadIcon = typeof column === 'object' && column?.popoverLink;

  const value = (typeof column === 'object' ? column.value : column) as string;
  const isNumeric = typeof column === 'object' ? (column.isNumeric as boolean) : false;
  const tableCellId = typeof column === 'object' ? (column.tableCellId as string) : '';

  if (columnWidths[colIdx] !== undefined) {
    tdStyles.width = columnWidths[colIdx];
  }

  const centerEmpty = typeof column === 'object' ? column.centerEmpty : false;
  const emptyCellStyles: ChakraStyledOptions =
    centerEmpty && value === EMPTY_CELL_VALUE ? { textAlign: 'center !important' } : {};

  const handlePopoverClick = () => {
    if (hasContent) {
      setIsOpen(!isOpen); // Toggle popover state
    }
  };

  return (
    <>
      <Td
        isNumeric={isNumeric}
        {...emptyCellStyles}
        {...tdStyles}
        id={tableCellId}
        onClick={(event) => {
          hasContent && event.stopPropagation();
          handlePopoverClick();
        }}
      >
        <Popover
          variant='default'
          placement={hasDownloadIcon ? 'bottom' : 'top'}
          offset={hasDownloadIcon ? [0, 0] : [250, -25]}
          closeOnBlur={false}
          isLazy
          isOpen={isOpen} // Pass isOpen state to control popover visibility
        >
          <PopoverAnchor>
            <Flex
              justifyContent={isNumeric ? 'flex-end' : 'flex-start'}
              alignItems='center'
              gap={1}
              _hover={{
                cursor: hasContent ? 'pointer' : 'default',
              }}
            >
              <>
                {value}
                {hasInfoIcon && <InfoIcon style={{ cursor: 'pointer' }} color='#E5E7EB' w={4} h={4} />}
                {hasDownloadIcon && <DownloadInvoiceLink uuid={column.invoiceUuid as string} />}
              </>
            </Flex>
          </PopoverAnchor>
          {hasContent && getContent()}
        </Popover>
      </Td>
    </>
  );
}
