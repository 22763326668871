import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '@app/hooks';
import { QuestionOutlineIcon } from '@chakra-ui/icons';
import { Box, Flex, Grid, GridItem, Link, Stack, Text, useToken } from '@chakra-ui/react';
import { BarGraph } from '@components/barGraph';
import { CustomerSegmentsInfo } from '@components/customerSegmentsInfo';
import { CustomLegends } from '@components/customLegends';
import { DataLoadingErrorAlert, EmptyDataAlert } from '@components/errorAlert';
import { LineGraph } from '@components/lineGraph';
import { LoadingSpinner } from '@components/loadingSpinner';
import { Stat } from '@components/stat';
import { BarDatum, BarSvgProps } from '@nivo/bar';
import { LineSvgProps } from '@nivo/line';
import _ from 'lodash';

import { Status, TransactionTypes, Verticals } from '@/common/interfaces';
import { getSegmentColor } from '@/common/utils/graphing';
import { displayPercent, getAmountOfTicksToRender } from '@/common/utils/utils';
import { selectActiveVertical } from '@/features/auth/userSlice';
import { selectDateRange, selectSelectedTransactionType } from '@/features/dashboard/slice';
import i18n from '@/i18n';

import {
  TCardContentDistributionsProps,
  TCardContentPromotionsProps,
  TTooltipData,
  TTotalsPromotionsProps,
} from './types';

function legendsFromData(keys: string[]) {
  return keys.map((key) => {
    return {
      label: i18n.t(`customersPage.customerAnalytics.tooltipSegmentNames.${key}`, { defaultValue: key }),
      color: getSegmentColor({ id: key }),
      divider: key === 'Margin',
    };
  });
}

const Promotions = ({ summaryData, promoData, loadingStatus, transactionType }: TCardContentPromotionsProps) => {
  const { t } = useTranslation();
  const selectedTransactionType = useAppSelector(selectSelectedTransactionType);
  const activeVertical = useAppSelector(selectActiveVertical);
  // setKeysToRender will be used when the user clicks on a legend
  const [keysToRender, setKeysToRender] = useState<string[]>(['All']);

  useEffect(() => {
    if (activeVertical === Verticals.Fuel && selectedTransactionType !== TransactionTypes.CStore) {
      setKeysToRender((state) => [...state, 'Margin']);
    } else {
      setKeysToRender((state) => state.filter((item) => item !== 'Margin'));
    }
  }, [activeVertical, selectedTransactionType]);

  const { temporalUnit } = useAppSelector(selectDateRange);
  const legends = useMemo(() => {
    if (!promoData?.promoAvgAcceptedLineGraph) {
      return [];
    }

    const keys = promoData?.promoAvgAcceptedLineGraph.map(({ id }) => String(id)).filter((key) => key !== 'label');
    if (activeVertical !== Verticals.Fuel || selectedTransactionType === TransactionTypes.CStore) {
      //for non-fueled verticals have to get rid of Margin option in the legend
      keys.pop();
    }
    return legendsFromData(keys);
  }, [activeVertical, promoData?.promoAvgAcceptedLineGraph, selectedTransactionType]);

  const filteredData = useMemo(() => {
    if (!promoData?.promoAvgAcceptedLineGraph) {
      return [];
    }

    const filtered = promoData?.promoAvgAcceptedLineGraph.filter((graph) => keysToRender.includes(String(graph.id)));
    return filtered.reverse();
  }, [promoData?.promoAvgAcceptedLineGraph, keysToRender]);

  const handleSelectLegendFilter = useCallback((value: string[]) => {
    setKeysToRender(value);
  }, []);

  const isError = loadingStatus === Status.Failed;
  if (isError) {
    return <DataLoadingErrorAlert />;
  }

  const isLoading = loadingStatus === Status.Loading;
  const isEmptySummaryData =
    loadingStatus === Status.Idle && _.get(summaryData, 'offerDistribution.lineGraph[0].data', []).length === 0;
  const isEmptyData = loadingStatus === Status.Idle && _.get(promoData, 'promoDistBarGraph[0].data', []).length === 0;

  const chartProps: Partial<LineSvgProps> = {
    legends: [],
    colors: getSegmentColor,
    ...(!keysToRender.length && { yScale: { type: 'linear', min: 0, max: 1 } }),
    axisBottom: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      renderTick: (tick: any) => {
        if (filteredData.length === 0) return <></>;

        const dataLength = filteredData[0].data.length;

        const MULTIPLY_VALUE = getAmountOfTicksToRender(dataLength);
        const shouldRender = tick.tickIndex % MULTIPLY_VALUE <= 0;

        if (dataLength < 12 || (dataLength > 12 && shouldRender)) {
          return (
            <g transform={`translate(${tick.x},${tick.y + 15})`}>
              <text
                x={0}
                y={0}
                textAnchor='middle'
                dominantBaseline='middle'
                style={{
                  fill: 'rgb(51, 51, 51)',
                  fontSize: 12,
                  fontFamily: 'sans-serif',
                }}
              >
                {tick.value}
              </text>
            </g>
          );
        }

        return <></>;
      },
    },
  };

  const titleSelector = () => {
    if (activeVertical === Verticals.Fuel && selectedTransactionType !== TransactionTypes.CStore) {
      return 'promotionsPage.promoDistGraph.title.fuel';
    } else if (activeVertical === Verticals.Restaurant) {
      return 'promotionsPage.promoDistGraph.title.restaurant';
    } else {
      return 'avgPromoOverTime';
    }
  };

  return (
    <>
      {isLoading ? (
        <Stack h={'100%'} w={'100%'} align={'center'} justify={'center'}>
          <LoadingSpinner />
        </Stack>
      ) : (
        <Flex
          w={'100%'}
          gap={4}
          pb={8}
          direction={{ base: 'column', xl: 'row', lg: 'column', md: 'column', sm: 'column' }}
        >
          <Stat
            value={isEmptySummaryData ? '-' : summaryData.offerDistribution?.stats.avgMargin}
            label={
              activeVertical === Verticals.Fuel
                ? t(`promotionsPage.promoStats.${activeVertical}.${selectedTransactionType}.averageMargin`)
                : t(`promotionsPage.promoStats.${activeVertical}.averageMargin`)
            }
            help={t(`promotionsPage.promoStats.${activeVertical}.averageMarginHelp`)}
          />
          <Stat
            value={isEmptySummaryData ? '-' : summaryData.offerDistribution?.stats.avgPromo}
            label={
              activeVertical === Verticals.Fuel
                ? t(`promotionsPage.promoStats.${activeVertical}.${selectedTransactionType}.averagePromotion`)
                : t(`promotionsPage.promoStats.${activeVertical}.averagePromotion`)
            }
            help={t(`promotionsPage.promoStats.${activeVertical}.averagePromotionHelp`)}
          />
          <Stat
            value={
              isEmptySummaryData
                ? '-'
                : displayPercent(summaryData.profitabilityWalkthroughData?.returnOnInvestment as string)
            }
            label={t(`promotionsPage.promoStats.${activeVertical}.roi`)}
            help={t(`promotionsPage.promoStats.${activeVertical}.roiHelp`)}
          />
          {activeVertical !== Verticals.Grocery && transactionType !== TransactionTypes.CStore && (
            <Stat
              value={isEmptySummaryData ? '-' : summaryData.offerDistribution?.stats.upsideFundedBoost}
              label={t(`promotionsPage.promoStats.${activeVertical}.upsideFundedBoosts`)}
              help={t(`promotionsPage.promoStats.${activeVertical}.upsideFundedBoostsHelp`)}
            />
          )}
        </Flex>
      )}

      {isEmptyData ? (
        <EmptyDataAlert />
      ) : (
        <>
          <Flex position='relative' w='full' flexDir='column'>
            {!keysToRender.length && (
              <Flex position='absolute' zIndex={1} width='full' height='full' justify='center' align='center'>
                <Text backgroundColor='white' border='1px dashed' borderColor='neutral.800' p='4'>
                  {i18n.t('promotionsPage.noSegmentsSelect') as string}
                </Text>
              </Flex>
            )}
            <LineGraph
              title={titleSelector()}
              data={filteredData}
              loadingStatus={loadingStatus}
              isCurrency={activeVertical === Verticals.Fuel && selectedTransactionType !== TransactionTypes.CStore}
              isPercentage={activeVertical !== Verticals.Fuel || selectedTransactionType === TransactionTypes.CStore}
              customProps={chartProps}
              temporalUnit={temporalUnit}
            />
          </Flex>
          {!!activeVertical && (
            <CustomLegends
              options={legends}
              defaultOptions={keysToRender}
              onChange={handleSelectLegendFilter}
              title={t(`promotionsPage.promoDistGraph.legendTitle.${activeVertical}`)}
            />
          )}
          <Flex mt='8' fontSize={'14px'}>
            <CustomerSegmentsInfo />
            {activeVertical === Verticals.Fuel && (
              <Link
                ml='4'
                href='https://fuelknowledgecenter.upside.com/hc/en-us/articles/10720486081307-How-Upside-protects-your-profit-as-margins-shift'
                isExternal
              >
                <h3>
                  <QuestionOutlineIcon boxSize={4} mr='1.5' />
                  {t(`promotionsPage.promoDistGraph.protectProfitMarginFaqLink`)}
                </h3>
              </Link>
            )}
          </Flex>
        </>
      )}
    </>
  );
};

const PromotionDistribution = ({
  data,
  loadingStatus,
  vertical: activeVertical,
  transactionType: selectedTransactionType,
}: TCardContentDistributionsProps) => {
  const { t } = useTranslation();

  // setKeysToRender will be used when the user clicks on a legend
  const [keysToRender, setKeysToRender] = useState<string[]>(['All']);

  const legends = useMemo(() => {
    if (!data?.promoDistBarGraph) {
      return [];
    }

    const keys = Object.keys(data?.promoDistBarGraph[0].data[0]).filter((key) => key !== 'label');
    return legendsFromData(keys);
  }, [data?.promoDistBarGraph]);

  const filteredData = useMemo(() => {
    if (!data?.promoDistBarGraph) {
      return [];
    }

    const filtered = data?.promoDistBarGraph[0].data.map((range: object) => {
      // [['label', '1-10'], ['All', {numberOfOffers: 1}]]
      return Object.entries(range).reduce((acc, [key, value]) => {
        if (key === 'label') {
          return { ...acc, [key]: value };
        }
        if (keysToRender.includes(key)) {
          return { ...acc, [key]: value.numOffers };
        }

        return acc;
      }, {});
    });

    return [{ data: filtered }];
  }, [keysToRender, data?.promoDistBarGraph]);

  const tooltipData = useMemo((): TTooltipData => {
    if (!data?.promoDistBarGraph) {
      return {};
    }

    const tooltip = data?.promoDistBarGraph[0].data.map((range: { label: string } & TTotalsPromotionsProps) => {
      const { label, ...rest } = range;

      return {
        [label]: rest,
      };
    });

    return tooltip.reduce((acc: TTooltipData, curr: TTooltipData) => {
      return { ...acc, ...curr };
    }, {});
  }, [data?.promoDistBarGraph]);

  const handleSelectLegendFilter = useCallback((value: string[]) => {
    setKeysToRender(value);
  }, []);

  const isError = loadingStatus === Status.Failed;

  if (isError) {
    return <DataLoadingErrorAlert />;
  }

  const isLoading = loadingStatus === Status.Loading;
  const isEmptyData = loadingStatus === Status.Idle && _.get(data, 'promoDistBarGraph[0].data', []).length === 0;

  const chartProps: Partial<BarSvgProps<BarDatum>> = {
    colors: getSegmentColor,
    legends: [],
    ...(!keysToRender.length && { enableGridY: true, gridYValues: [0, 0.5, 1] }),
  };

  chartProps.tooltip = (point) => {
    return (
      <Popover
        point={point}
        segment={point.id}
        activeVertical={activeVertical}
        tooltipData={tooltipData}
        selectedTransactionType={selectedTransactionType}
      />
    );
  };

  return (
    <>
      <Flex w={'100%'} gap={4} pb={8}>
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          data.promoDistributionStats?.map((stat, id) => {
            return <Stat key={id} {...stat} />;
          })
        )}
      </Flex>

      {isEmptyData ? (
        <EmptyDataAlert />
      ) : (
        <>
          <Flex position='relative' w='full' flexDir='column' mb={8}>
            {!keysToRender.length && (
              <Flex position='absolute' zIndex={1} width='full' height='full' justify='center' align='center'>
                <Text backgroundColor='white' border='1px dashed' borderColor='neutral.800' p='4'>
                  {i18n.t('promotionsPage.noSegmentsSelect') as string}
                </Text>
              </Flex>
            )}
            <BarGraph
              title={'promotionsPage.promotionDistByCustSeg.subTitle'}
              yAxisLabel={'promotionsPage.promoDistGraph.percentOfUpsideTxes'}
              data={filteredData}
              loadingStatus={loadingStatus}
              isPercentage
              customProps={chartProps}
            />
          </Flex>
          <CustomLegends
            options={legends}
            defaultOptions={keysToRender}
            onChange={handleSelectLegendFilter}
            title={t(`promotionsPage.promotionDistByCustSeg.standardLegendTitle`)}
          />
          {activeVertical === Verticals.Fuel && (
            <Link
              ml='4'
              href='https://fuelknowledgecenter.upside.com/hc/en-us/articles/10597635594395-How-Upside-s-personalized-promotions-are-generate'
              isExternal
              mt='4'
              fontSize={'14px'}
            >
              <h3>
                <QuestionOutlineIcon boxSize={4} mr='1.5' />
                {t(`promotionsPage.promotionDistByCustSeg.protectProfitMarginFaqLink`)}
              </h3>
            </Link>
          )}
        </>
      )}
    </>
  );
};

export const CardViewContents = {
  Promotions,
  PromotionDistribution,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Popover = (props: any) => {
  const { point, segment, activeVertical, tooltipData, selectedTransactionType } = props;
  const [neutral100] = useToken('colors', ['neutral.100']);

  const { t } = useTranslation();

  const css = () => `
  .promo-dist-tooltip-line:nth-child(2n) {
    background-color: ${neutral100};
  }`;

  return (
    <>
      <Grid
        templateColumns='1fr'
        rowGap={1}
        columnGap={2}
        bgColor='white'
        shadow='base'
        borderRadius={10}
        padding={4}
        width={'100%'}
        zIndex={100}
      >
        {point.indexValue === '0¢' || point.indexValue === '0%' ? (
          <Box width={'350px'}>
            <GridItem fontSize='16px' padding='8px' lineHeight={'24px'}>
              {segment === 'All' ? `${segment} customer segments detail` : `${segment} customer segment detail`}
            </GridItem>
            <GridItem fontSize='14px' bgColor='surface.default' padding='8px' lineHeight={'24px'}>
              {t(`promotionsPage.promoDistGraph.tooltip.${activeVertical}.noPromo`)}
            </GridItem>
            {activeVertical === Verticals.Fuel && selectedTransactionType === TransactionTypes.Gas && (
              <Flex justifyContent={'space-between'}>
                <GridItem fontSize='14px' padding='8px' lineHeight={'24px'}>
                  {t(`promotionsPage.promoDistGraph.tooltip.${activeVertical}.gallons`)}
                </GridItem>
                <GridItem fontSize='14px' padding='8px' lineHeight={'24px'} fontWeight={700}>
                  {tooltipData[point.indexValue][String(point.id) as keyof TTotalsPromotionsProps].numGallons}
                </GridItem>
              </Flex>
            )}
            <Flex justifyContent={'space-between'} bgColor='surface.default'>
              <GridItem fontSize='14px' padding='8px' lineHeight={'24px'}>
                {t(`promotionsPage.promoDistGraph.tooltip.${activeVertical}.transactions`)}
              </GridItem>
              <GridItem fontSize='14px' padding='8px' lineHeight={'24px'} fontWeight={700}>
                {tooltipData[point.indexValue][String(point.id) as keyof TTotalsPromotionsProps].numOffersPerBucket}
              </GridItem>
            </Flex>
          </Box>
        ) : (
          <>
            <style>{css()}</style>
            <GridItem fontSize='16px' padding='8px' lineHeight={'24px'}>
              {segment === 'All' ? `${segment} customer segments detail` : `${segment} customer segment detail`}
            </GridItem>
            {activeVertical === Verticals.Fuel && selectedTransactionType === TransactionTypes.Gas && (
              <Flex justifyContent={'space-between'} className='promo-dist-tooltip-line'>
                <GridItem fontSize='14px' padding='8px' lineHeight={'24px'}>
                  {t(`promotionsPage.promoDistGraph.tooltip.${activeVertical}.gallons`)}
                </GridItem>
                <GridItem fontSize='14px' padding='8px' lineHeight={'24px'} fontWeight={700}>
                  {tooltipData[point.indexValue][String(point.id) as keyof TTotalsPromotionsProps].numGallons}
                </GridItem>
              </Flex>
            )}
            <Flex justifyContent={'space-between'} className='promo-dist-tooltip-line'>
              <GridItem fontSize='14px' padding='8px' lineHeight={'24px'}>
                {t(`promotionsPage.promoDistGraph.tooltip.${activeVertical}.transactions`)}
              </GridItem>
              <GridItem fontSize='14px' padding='8px' lineHeight={'24px'} fontWeight={700}>
                {tooltipData[point.indexValue][String(point.id) as keyof TTotalsPromotionsProps].numOffersPerBucket}
              </GridItem>
            </Flex>
            {activeVertical === Verticals.Fuel && selectedTransactionType !== TransactionTypes.CStore && (
              <Flex justifyContent={'space-between'} className='promo-dist-tooltip-line'>
                <GridItem fontSize='14px' padding='8px' lineHeight={'24px'}>
                  {selectedTransactionType !== TransactionTypes.Gas
                    ? t(`promotionsPage.promoDistGraph.tooltip.restaurant.averageMargin`)
                    : t(`promotionsPage.promoDistGraph.tooltip.${activeVertical}.averageMargin`)}
                </GridItem>
                <GridItem fontSize='14px' padding='8px' lineHeight={'24px'} fontWeight={700}>
                  {tooltipData[point.indexValue][String(point.id) as keyof TTotalsPromotionsProps].avgMargin}
                </GridItem>
              </Flex>
            )}
            <Flex justifyContent={'space-between'} className='promo-dist-tooltip-line'>
              <GridItem fontSize='14px' padding='8px' lineHeight={'24px'}>
                {selectedTransactionType !== TransactionTypes.Gas
                  ? t(`promotionsPage.promoDistGraph.tooltip.restaurant.averagePromotion`)
                  : t(`promotionsPage.promoDistGraph.tooltip.${activeVertical}.averagePromotion`)}
              </GridItem>
              <GridItem fontSize='14px' padding='8px' lineHeight={'24px'} fontWeight={700}>
                {tooltipData[point.indexValue][String(point.id) as keyof TTotalsPromotionsProps].avgPromotion}
              </GridItem>
            </Flex>
            {activeVertical !== Verticals.Grocery && (
              <Flex justifyContent={'space-between'} className='promo-dist-tooltip-line'>
                <GridItem fontSize='14px' padding='8px' lineHeight={'24px'}>
                  {t(`promotionsPage.promoDistGraph.tooltip.${activeVertical}.returnInvestment`)}
                </GridItem>
                <GridItem fontSize='14px' padding='8px' lineHeight={'24px'} fontWeight={700}>
                  {tooltipData[point.indexValue][String(point.id) as keyof TTotalsPromotionsProps].avgROI}
                </GridItem>
              </Flex>
            )}
          </>
        )}
      </Grid>
    </>
  );
};
