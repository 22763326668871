import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { useAuth0 } from '@auth0/auth0-react';
import Hotjar from '@hotjar/browser';

import { RequestPayload } from '@/common/interfaces';
import { whichVerticalFromRoles } from '@/config/mvMapping';
import { checkIsUpsider, selectAuthRequestPath, selectDashboardUser, setUser } from '@/features/auth/userSlice';
import {
  generalConfigsFetchRequest,
  headerDataFetchRequest,
  masqueradingListsFetchRequest,
  parentOrgConfigFetchRequest,
  setIsDemo,
} from '@/features/dashboard/slice';

import { useFetchUserProfile } from './fetchUserSettings';
import { useFetchDataCheck } from './utils';

export const useInitializeUser = () => {
  const dispatch = useAppDispatch();
  const { user, isLoading } = useAuth0();

  let userJson = '';
  // this does a specific check for the useEffect to trigger on
  // this fixes a double trigger of the use effect when simply passing the object "user"
  if (user) {
    userJson = JSON.stringify({
      sub: user?.sub,
      name: user?.name,
      email: user?.email,
      org_id: user?.org_id,
      roles: user['dashboard/roles'],
    });
  }

  useFetchUserProfile();

  useEffect(() => {
    console.debug('user effect called');

    if (user && user.sub && !isLoading) {
      console.debug(JSON.stringify(user));
      const roles = user['dashboard/roles'];
      const email = user.email;

      Hotjar.identify(user.sub, {
        name: user.name as string,
        email: email as string,
        orgId: user.org_id,
        orgDisplayName: user.org.display_name,
        orgName: user.org.name,
        roles: roles.join(','),
        isUpside: user.email?.includes('upside') as boolean,
      });

      const isDemoOrg = user?.orgMetadata?.isDemo === 'true' || false;
      const isDemoHostName = window.location.hostname.includes('demo');
      const isDemoDashboard = isDemoOrg || isDemoHostName;
      console.log('isDemoDashboard', isDemoDashboard);
      dispatch(setIsDemo(isDemoDashboard));

      const isParentOrg = !!user?.orgMetadata?.merchantUuids;
      const vertical = whichVerticalFromRoles(roles);
      const userPayload = {
        email: email || '',
        roles: user['dashboard/roles'],
        org_id: user.org_id,
        vertical: vertical,
        isParentOrg: isParentOrg,
      };

      dispatch(setUser(userPayload));

      const role =
        roles.find((element: string) => element.includes('ReadOnly') || element.includes('UpsideManager')) || '';

      const generalPayload: RequestPayload = { authRequestPath: `${user.org_id}/${role}/${vertical}` };
      const payload = { org_id: user.org_id, roles: roles };
      const isUpsider = checkIsUpsider(payload);

      if (isUpsider) {
        console.debug('am upsider, fetch masquerade lists');
        const payload: RequestPayload = { authRequestPath: `${user.org_id}/${role}/${vertical}` };
        dispatch(masqueradingListsFetchRequest(payload));
      } else if (isParentOrg) {
        console.debug('am parent org, fetch parent org config');
        dispatch(parentOrgConfigFetchRequest(generalPayload));
      }
      console.debug('user initialized');

      dispatch(generalConfigsFetchRequest(generalPayload));
    }
  }, [userJson]);
};

export const useFetchHeaderData = () => {
  const dispatch = useAppDispatch();
  const authRequestPath = useAppSelector(selectAuthRequestPath);
  const canFetchData = useFetchDataCheck('headers', false, false);
  const user = useAppSelector(selectDashboardUser);

  let userContext = '';

  // this does a specific check for the useEffect to trigger on
  // this will cause the useEffect to trigger if any of these attributes below changes
  if (user) {
    userContext = JSON.stringify({
      merchantUuid: user.activeMerchantUuid,
      userUuid: user.activeUserUuid,
      isDealer: user.isDealer,
      isParentOrg: user.isParentOrg,
      vertical: user.activeVertical,
      merchantName: user.activeMerchantName,
    });
  }

  useEffect(() => {
    if (canFetchData) {
      const payload: RequestPayload = { authRequestPath };
      dispatch(headerDataFetchRequest(payload));
    }
  }, [dispatch, authRequestPath, canFetchData, userContext]);
};

// This hook is used to determine if the dashboard is a demo dashboard based on the URL in the browser
// Will try to attempt to either limit or hardcode which orgs can be logged into based on this
// Depends on MD-1652 and some FE updates to see if we can bend auth0's react SDK to our will
export const useIsDemoDashboard = () => {
  const dispatch = useAppDispatch();

  const isDemoHostName = window.location.hostname.includes('demo');

  useEffect(() => {
    dispatch(setIsDemo(isDemoHostName));
  }, [isDemoHostName]);
};
