import { useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, AlertIcon, Box, Flex, Text, Wrap } from '@chakra-ui/react';
import { useDecision } from '@optimizely/react-sdk';
import { intervalToDuration } from 'date-fns';

import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { Card } from '@/common/components/card';
import { Section } from '@/common/components/section';
import { Status } from '@/common/interfaces';
import { selectBehaviorWalkthrough, selectCalculationDates, setBehaviorWalkthrough } from '@/features/dashboard/slice';

import { CardsViewContent } from './CardsContentGenerator';
import { selectBehaviorStatus } from './slice';

const MIN_MONTHS_TO_ENABLE = 2;

export const Behavior = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const prePostRef = useRef<null | HTMLDivElement>(null);
  const onOffRef = useRef<null | HTMLDivElement>(null);
  const greenGreyRef = useRef<null | HTMLDivElement>(null);
  const pageRef = useRef<null | HTMLDivElement>(null);

  const [behaviorFlag] = useDecision('md-640', { autoUpdate: true });
  const behaviorWalkthroughState = useAppSelector(selectBehaviorWalkthrough);
  const { minDate } = useAppSelector(selectCalculationDates);
  const behaviorStatus = useAppSelector(selectBehaviorStatus);

  useEffect(() => {
    pageRef.current?.scrollIntoView();
  }, [pageRef]);

  const hasEnoughData = useMemo(() => {
    if (minDate) {
      const [year, month, day] = minDate.split('-').map((item) => Number(item));
      const { months, years } = intervalToDuration({
        start: new Date(year, month - 1, day),
        end: new Date(),
      });

      if (years && years >= 1) return true;

      if (months && months >= MIN_MONTHS_TO_ENABLE) return true;

      return false;
    }

    return false;
  }, [minDate]);

  const setOpacity = (step: number) => {
    if (!behaviorWalkthroughState || !behaviorWalkthroughState.initiated) return 1;

    return behaviorWalkthroughState &&
      behaviorWalkthroughState.initiated &&
      (behaviorWalkthroughState.step >= step || behaviorWalkthroughState.step === 0)
      ? 1
      : 0.3;
  };

  useEffect(() => {
    if (behaviorWalkthroughState && behaviorWalkthroughState.step !== 0 && behaviorWalkthroughState.step < 5) {
      if (prePostRef) prePostRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
    if (behaviorWalkthroughState && behaviorWalkthroughState.step >= 5 && behaviorWalkthroughState.step < 9) {
      if (onOffRef) onOffRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
    if (behaviorWalkthroughState && behaviorWalkthroughState.step >= 9) {
      if (greenGreyRef) greenGreyRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [behaviorWalkthroughState, onOffRef, prePostRef]);

  const walkthroughExitHandler = () => {
    dispatch(setBehaviorWalkthrough({ initiated: false, step: 0 }));

    localStorage.setItem('behaviorWalkthrough', JSON.stringify({ initiated: false, step: 0 }));
  };

  const walkthroughIncreaseClickHandler = () => {
    behaviorWalkthroughState &&
      localStorage.setItem(
        'behaviorWalkthrough',
        JSON.stringify({ initiated: true, step: behaviorWalkthroughState.step + 1 }),
      );
    behaviorWalkthroughState &&
      dispatch(setBehaviorWalkthrough({ initiated: true, step: behaviorWalkthroughState.step + 1 }));
  };

  const walkthroughDecreaseClickHandler = () => {
    behaviorWalkthroughState &&
      localStorage.setItem(
        'behaviorWalkthrough',
        JSON.stringify({ initiated: true, step: behaviorWalkthroughState.step - 1 }),
      );
    behaviorWalkthroughState && behaviorWalkthroughState.step !== 0
      ? dispatch(setBehaviorWalkthrough({ initiated: true, step: behaviorWalkthroughState.step - 1 }))
      : dispatch(setBehaviorWalkthrough({ initiated: true, step: 1 }));
  };

  const walkthroughStartHandler = (stepNum: number) => {
    localStorage.setItem('behaviorWalkthrough', JSON.stringify({ initiated: true, step: stepNum }));
    dispatch(setBehaviorWalkthrough({ initiated: true, step: stepNum }));
  };

  return (
    <Box ref={pageRef} padding={0} margin={0} maxW='100%'>
      {behaviorFlag.enabled && (
        <>
          {!hasEnoughData && behaviorStatus !== Status.Loading && (
            <Alert status='info'>
              <AlertIcon color='icon.default-inverse' />
              <Text color='text.default-inverse'>{t('behaviorPage.notEnoughData')}</Text>
            </Alert>
          )}
          <Wrap
            direction='column'
            gap={2}
            padding={2}
            display={behaviorFlag.enabled ? 'normal' : 'none'}
            pointerEvents={hasEnoughData ? 'all' : 'none'}
            opacity={hasEnoughData ? 1 : 0.3}
          >
            <Wrap direction='column'>
              <Section name='behaviorPage.customerBehaviorGraph.title'>
                <Flex justifyContent='center' gap={4} ref={prePostRef}>
                  <Card>
                    <CardsViewContent.PrePostControlGraph
                      hasEnoughData={hasEnoughData}
                      walkthroughExitHandler={walkthroughExitHandler}
                      walkthroughIncreaseClickHandler={walkthroughIncreaseClickHandler}
                      walkthroughDecreaseClickHandler={walkthroughDecreaseClickHandler}
                      walkthroughStartHandler={walkthroughStartHandler}
                    />
                  </Card>
                </Flex>
              </Section>
            </Wrap>
            <Wrap direction='column' opacity={setOpacity(5)}>
              <Section name='behaviorPage.onOffGraph.title' />
              <Flex justifyContent='center' gap={4} ref={onOffRef}>
                <Card>
                  <CardsViewContent.OnOffGraph
                    hasEnoughData={hasEnoughData}
                    walkthroughExitHandler={walkthroughExitHandler}
                    walkthroughIncreaseClickHandler={walkthroughIncreaseClickHandler}
                    walkthroughDecreaseClickHandler={walkthroughDecreaseClickHandler}
                    walkthroughStartHandler={walkthroughStartHandler}
                  />
                </Card>
              </Flex>
            </Wrap>

            <Wrap direction='column' opacity={setOpacity(9)}>
              <Section name='behaviorPage.incrementalImpactGraph.title' />
              <Flex justifyContent='center' gap={4} ref={greenGreyRef}>
                <Card>
                  <CardsViewContent.IncrementalImpact
                    walkthroughExitHandler={walkthroughExitHandler}
                    walkthroughIncreaseClickHandler={walkthroughIncreaseClickHandler}
                    walkthroughDecreaseClickHandler={walkthroughDecreaseClickHandler}
                    walkthroughStartHandler={walkthroughStartHandler}
                  />
                </Card>
              </Flex>
            </Wrap>
          </Wrap>
        </>
      )}
    </Box>
  );
};
