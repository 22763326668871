import upsideLogo from '@assets/images/upside-logo.png';
import { chakra, forwardRef, HTMLChakraProps, ImageProps, usePrefersReducedMotion } from '@chakra-ui/react';
import { keyframes } from '@emotion/react';

export const LogoIcon = forwardRef<ImageProps, 'img'>((props, ref) => {
  const prefersReducedMotion = usePrefersReducedMotion();
  const spin = keyframes`
  from { transform: rotate(360deg); }
  to { transform: rotate(0deg); }
`;
  const animation = prefersReducedMotion ? undefined : `${spin} infinite 20s linear`;

  return <chakra.img animation={animation} src={upsideLogo} ref={ref} {...props} />;
});

export const Logo = (props: HTMLChakraProps<'svg'>) => (
  <chakra.svg
    fill='neutral.50'
    height='8'
    width='auto'
    viewBox='0 0 130 30'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path d='M38.7915 17.2615V7.97168H43.0858V16.4934C43.0858 18.363 43.6064 20.3775 46.2524 20.3775C48.8984 20.3775 49.4767 18.363 49.4767 16.4934V7.97168H53.6843V17.2036C53.6843 21.0297 51.2118 24.2036 46.2524 24.2036C41.3219 24.2036 38.7915 21.0297 38.7915 17.2615Z' />
    <path d='M65.8009 7.76855C63.3284 7.76855 61.4777 8.65261 60.2776 10.3917V7.97145H56.2291V28.6526H60.4945V21.957C61.5066 23.2033 63.0826 24.0004 65.3671 24.0004C69.2711 24.0004 72.9003 21.0439 72.9003 15.841C72.9003 10.5077 69.647 7.76855 65.8009 7.76855ZM64.4562 20.1744C62.1139 20.1744 60.4077 18.6526 60.4077 15.841C60.4077 13.0874 62.244 11.6236 64.702 11.6236C66.8709 11.6236 68.6059 13.0584 68.6059 15.928C68.6059 18.7686 66.7407 20.1744 64.4562 20.1744Z' />
    <path d='M74.1003 18.5511C75.5318 19.5656 77.6428 20.5656 80.4334 20.5656C82.1395 20.5656 83.0504 20.1018 83.0504 19.2178C83.0504 18.3047 81.8359 18.0004 79.3923 17.4496C75.6764 16.5656 74.1437 15.2467 74.1437 12.6236C74.1437 9.1453 76.8909 7.58008 80.7515 7.58008C83.2529 7.58008 85.2048 8.1308 86.3037 8.79747V12.8554C85.0892 11.9714 82.7613 11.2902 80.6647 11.2902C79.2622 11.2902 78.3513 11.6236 78.3513 12.5076C78.3513 13.3627 79.1754 13.696 81.1852 14.1308C85.5229 15.0294 87.1423 16.3772 87.1423 19.1308C87.1423 23.5946 82.848 24.2033 80.2888 24.2033C77.6717 24.2033 75.3438 23.4352 74.1003 22.5511V18.5511Z' />
    <path d='M88.4147 3.72533C88.4147 2.23258 89.5136 1.24707 91.1041 1.24707C92.8681 1.24707 93.8224 2.21808 93.8224 3.72533C93.8224 5.16011 92.8825 6.17461 91.1041 6.17461C89.5136 6.17461 88.4147 5.16011 88.4147 3.72533Z' />
    <path d='M93.2585 7.97168H88.9931V23.7978H93.2585V7.97168Z' />
    <path d='M107.66 1.62402V9.81243C106.648 8.56605 105.071 7.76895 102.787 7.76895C98.883 7.76895 95.2249 10.7255 95.2249 15.9284C95.2249 21.2762 98.5216 24.0298 102.353 24.0298C104.826 24.0298 106.676 23.1458 107.877 21.4066V23.8269H111.925V1.62402H107.66ZM103.452 20.1748C101.283 20.1748 99.5481 18.74 99.5481 15.8704C99.5481 13.0298 101.413 11.624 103.669 11.624C106.04 11.624 107.79 13.1458 107.79 15.9574C107.775 18.6385 105.881 20.1748 103.452 20.1748Z' />
    <path d='M121.772 7.58008C117.405 7.58008 113.819 10.5801 113.819 16.1018C113.819 21.4786 117.636 24.2033 122.104 24.2033C124.822 24.2033 127.165 23.5366 128.481 22.3047V18.638C126.861 19.9569 124.938 20.4786 122.654 20.4786C120.369 20.4786 118.576 19.7395 117.983 17.5511H128.987C129.941 10.2323 125.878 7.58008 121.772 7.58008ZM117.926 14.4496C118.316 12.1598 119.689 11.2178 121.887 11.2178C123.724 11.2178 125.112 12.0438 125.271 14.4207L117.926 14.4496Z' />
    <path d='M28.8727 7.9707V16.1446C28.6703 23.7533 22.4529 29.8693 14.8041 29.8693C7.16978 29.8693 0.952426 23.7533 0.75 16.1446V7.9707H8.02286V16.1446C8.22529 19.7388 11.1749 22.5794 14.8041 22.5794C18.4333 22.5794 21.3974 19.7388 21.5998 16.1446V7.9707H28.8727Z' />
    <path d='M14.8185 14.7391L29.5088 0H14.8185V14.7391Z' />
  </chakra.svg>
);

export const LogoBlue = (props: HTMLChakraProps<'svg'>) => (
  <chakra.svg
    fill='purple.1200'
    height='8'
    width='auto'
    viewBox='0 0 130 30'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path d='M38.7915 17.2615V7.97168H43.0858V16.4934C43.0858 18.363 43.6064 20.3775 46.2524 20.3775C48.8984 20.3775 49.4767 18.363 49.4767 16.4934V7.97168H53.6843V17.2036C53.6843 21.0297 51.2118 24.2036 46.2524 24.2036C41.3219 24.2036 38.7915 21.0297 38.7915 17.2615Z' />
    <path d='M65.8009 7.76855C63.3284 7.76855 61.4777 8.65261 60.2776 10.3917V7.97145H56.2291V28.6526H60.4945V21.957C61.5066 23.2033 63.0826 24.0004 65.3671 24.0004C69.2711 24.0004 72.9003 21.0439 72.9003 15.841C72.9003 10.5077 69.647 7.76855 65.8009 7.76855ZM64.4562 20.1744C62.1139 20.1744 60.4077 18.6526 60.4077 15.841C60.4077 13.0874 62.244 11.6236 64.702 11.6236C66.8709 11.6236 68.6059 13.0584 68.6059 15.928C68.6059 18.7686 66.7407 20.1744 64.4562 20.1744Z' />
    <path d='M74.1003 18.5511C75.5318 19.5656 77.6428 20.5656 80.4334 20.5656C82.1395 20.5656 83.0504 20.1018 83.0504 19.2178C83.0504 18.3047 81.8359 18.0004 79.3923 17.4496C75.6764 16.5656 74.1437 15.2467 74.1437 12.6236C74.1437 9.1453 76.8909 7.58008 80.7515 7.58008C83.2529 7.58008 85.2048 8.1308 86.3037 8.79747V12.8554C85.0892 11.9714 82.7613 11.2902 80.6647 11.2902C79.2622 11.2902 78.3513 11.6236 78.3513 12.5076C78.3513 13.3627 79.1754 13.696 81.1852 14.1308C85.5229 15.0294 87.1423 16.3772 87.1423 19.1308C87.1423 23.5946 82.848 24.2033 80.2888 24.2033C77.6717 24.2033 75.3438 23.4352 74.1003 22.5511V18.5511Z' />
    <path d='M88.4147 3.72533C88.4147 2.23258 89.5136 1.24707 91.1041 1.24707C92.8681 1.24707 93.8224 2.21808 93.8224 3.72533C93.8224 5.16011 92.8825 6.17461 91.1041 6.17461C89.5136 6.17461 88.4147 5.16011 88.4147 3.72533Z' />
    <path d='M93.2585 7.97168H88.9931V23.7978H93.2585V7.97168Z' />
    <path d='M107.66 1.62402V9.81243C106.648 8.56605 105.071 7.76895 102.787 7.76895C98.883 7.76895 95.2249 10.7255 95.2249 15.9284C95.2249 21.2762 98.5216 24.0298 102.353 24.0298C104.826 24.0298 106.676 23.1458 107.877 21.4066V23.8269H111.925V1.62402H107.66ZM103.452 20.1748C101.283 20.1748 99.5481 18.74 99.5481 15.8704C99.5481 13.0298 101.413 11.624 103.669 11.624C106.04 11.624 107.79 13.1458 107.79 15.9574C107.775 18.6385 105.881 20.1748 103.452 20.1748Z' />
    <path d='M121.772 7.58008C117.405 7.58008 113.819 10.5801 113.819 16.1018C113.819 21.4786 117.636 24.2033 122.104 24.2033C124.822 24.2033 127.165 23.5366 128.481 22.3047V18.638C126.861 19.9569 124.938 20.4786 122.654 20.4786C120.369 20.4786 118.576 19.7395 117.983 17.5511H128.987C129.941 10.2323 125.878 7.58008 121.772 7.58008ZM117.926 14.4496C118.316 12.1598 119.689 11.2178 121.887 11.2178C123.724 11.2178 125.112 12.0438 125.271 14.4207L117.926 14.4496Z' />
    <path
      d='M28.8727 7.9707V16.1446C28.6703 23.7533 22.4529 29.8693 14.8041 29.8693C7.16978 29.8693 0.952426 23.7533 0.75 16.1446V7.9707H8.02286V16.1446C8.22529 19.7388 11.1749 22.5794 14.8041 22.5794C18.4333 22.5794 21.3974 19.7388 21.5998 16.1446V7.9707H28.8727Z'
      fill='#0047FF'
    />
    <path d='M14.8185 14.7391L29.5088 0H14.8185V14.7391Z' fill='#0047FF' />
  </chakra.svg>
);
